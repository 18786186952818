import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {  TextField } from "@mui/material";
import "./GetFaqModal.css";
import { addDoc, collection,doc } from "firebase/firestore";
import db from "../Announcment/firebase";
import { useAdminContext } from "../Context/contextStore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  backgroundColor:'#e3e9ee'
};

export default function GetFaqModal({ category, id,refreshData,close }) {
  const { faqId } = useAdminContext();
  const [open, setOpen] = React.useState(false);
  const [faqValue, setFaqValue] = React.useState({
    question: "",
    answer: "",
    link: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // handle inputs
  const handleQuestion = (e) => {
    setFaqValue({
      ...faqValue,
      question: e.target.value,
    });
  };
  const handleAnswer = (e) => {
    setFaqValue({
      ...faqValue,
      answer: e.target.value,
    });
  };
  const handleLink = (e) => {
    setFaqValue({
      ...faqValue,
      link: e.target.value,
    });
  };

  // // sending FAQ to firestore
  // function reloadPage() {
  //   window.location.reload(true);
  // }

  const sendFaqToDB = async () => {
    const { question, answer, link } = faqValue;
  
    if (question && answer) {
      try {
        // Access the main document using the selected category's ID
        const mainDocRef = doc(db, 'FAQs', faqId);
  
        // Access the specific nested collection within the main document
        const nestedCollectionRef = collection(mainDocRef, "questions");
  
        // Add a new question-answer document to the nested collection
        await addDoc(nestedCollectionRef, {
          question: question,
          answer: answer,
          link: link,
        });
  
        close();
        refreshData();
        // Reset the input values
        setFaqValue({
          question: "",
          answer: "",
          link: "",
        });
        // reloadPage();
        // Provide user feedback here if needed
        console.log("New question-answer added successfully!");
      } catch (error) {
        console.log("Error", error);
      }
    } else {
      alert('Please add both Question and Answer');
      handleOpen();
    }
  };
  



  return (
      <div>
        <Button
          onClick={handleOpen}
          className="CategoryBtn"
          style={{ width: "100%" }}
        >
          {category.text}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ fontWeight: "600",color:'#013191' }}
            >
              {category.text}'s FAQ
            </Typography>
            <TextField
              id="outlined-basic"
              label="Type Question"
              variant="outlined"
              value={faqValue.question}
              onChange={(e) => handleQuestion(e)}
              style={{ width: '19rem', borderRadius: '12px',lineHeight:'1em',marginBottom:'0.5rem'}}
            />
            <TextField
              id="outlined-basic"
              label="Type Answer"
              variant="outlined"
              value={faqValue.answer}
              onChange={(e) => handleAnswer(e)}
              style={{ width: '19rem', borderRadius: '12px',lineHeight:'1em',marginBottom:'0.5rem'}}
            />
            <TextField
              id="outlined-basic"
              label="Enter Link"
              variant="outlined"
              value={faqValue.link}
              onChange={(e) => handleLink(e)}
              style={{ width: '19rem', borderRadius: '12px',lineHeight:'1em',marginBottom:'0.5rem'}}
            />

            <button
              className="addFaq"
              onClick={() => {
                handleClose();
                sendFaqToDB();
              }}
            >
              Add FAQ
            </button>
          </Box>
        </Modal>
      </div>
  );
}
