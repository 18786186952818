import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import "./Modal.css";
import { doc, updateDoc } from "firebase/firestore";
import db from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
  backgroundColor: '#e3e9ee',
  borderRadius:'12px'
};

function Modall({ buttonName, docId, docText,docSquadron,refreshData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState(docText);
  const [sqd, setSqd] = useState(docSquadron);

  // update Announcement
  const updateAnnouncement = async () => {
    if (docText.toUpperCase() === data.toUpperCase() && sqd === docSquadron) {
      alert('Please make some updation');
    } else {
      
      try {
        const updateRef = doc(db, "Announcements", `${docId}`);
        await updateDoc(updateRef, {
          text: data,
          squadronNo:sqd,
        });
        updateToast();
        refreshData();
      } catch (error) {
        console.log("Error", error);
      }
    }
    
  };



  //   update toast message function
  const updateToast = () => {
    toast("Announcement updated 📑", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };



  return (
    <div>
      <Button onClick={handleOpen} className="updateBtnModel">
        {buttonName}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {/* <ToastContainer pauseOnHover /> */}
          <Typography id="modal-modal-title" variant="h5" component="h2" mb={2}>
            Update Announcement
          </Typography>
          <TextField
            id="outlined-basic"
            label="Update Announcement"
            variant="outlined"
            value={data}
            onChange={(e) =>setData(
              e.target.value,
             )}
            style={{ width: '19rem', borderRadius: '12px',lineHeight:'1em',marginBottom:'0.5rem'}}
          />
          <TextField
            id="outlined-basic"
            label="Update Squadron"
            variant="outlined"
            value={sqd&&sqd}
            onChange={(e) => setSqd(e.target.value)}
            style={{ width: '19rem', borderRadius: '12px',lineHeight:'1em',marginBottom:'0.5rem'}}
          />
          <Button
            variant="outlined"
            color="primary"
            className="UpdateAnnouncment"
            onClick={() => {
              updateAnnouncement();
              setOpen(false);
            }}
          >
            Update
          </Button>
          
        </Box>
      </Modal>
    </div>
  );
}

export default Modall;
