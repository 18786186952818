import React, { useEffect, useState } from "react";
import "./FAQ.css";
import Footer from "../../components/Footer/Footer";
import { Grid } from "@mui/material";
import {
  getDocs,
  query,
  collection,
  onSnapshot,
  doc,
  getDoc,
  where,
  deleteDoc,
} from "firebase/firestore";
import db from "../Announcment/firebase";
import Header from "./Header";
import Categories from "./categories";
import CategoriesQuestionAnswer from "./CategoriesQA";
import { RotatingLines } from "react-loader-spinner";
import AddQuestionModal from "./addQustionModal";
import { useAdminContext } from "../Context/contextStore";
import AddCategory from "./AddCategory";
import { AiFillDelete } from "react-icons/ai";

function FAQ() {
  const { faqData, faqIdFun } = useAdminContext();
  const [faqCategories, setFaqCategories] = useState([]);
  const [CategoriesQA, setCategoriesQA] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [sqdData, setSqdData] = useState([]);
  const [filterFaq, setFilterFaq] = useState([]);
  const [defaultQACategory, setDefaultQACategory] = useState("");
  const [handleError, setError] = useState("");
  const [sqdNo, setSqdNo] = useState();
  const [show, setShow] = useState(false);
  const [selectedSquadron, setSelectedSquadron] = useState(null);
  const [selectedTab, setSelectedTab] = useState("");


  const getFaqCategories = async () => {
    try {
      setIsloading(true);
      setFilterFaq([]);
      setFaqCategories([]);
      const que = query(collection(db, "FAQs"));
      const snapShot = await getDocs(que);
      const categoriesData = [];
  
      snapShot.forEach((elem) => {
        const category = {
          text: elem.data().name,
          squadronNo: elem.data().squadronNo,
          id: elem.id,
        };
  
        categoriesData.push(category);
      });
   setFaqCategories(categoriesData);

  
      if (selectedSquadron) {
        // Filter categories based on selectedSquadron if it's defined
        const filteredCategories = categoriesData.filter((item) => {
          return item.squadronNo === selectedSquadron;
        });
  
        setFilterFaq(filteredCategories);
        if (filteredCategories.length > 0) {
          setShow(true)
          // Set the default category to the first category in filteredCategories
          setDefaultQACategory(filteredCategories[0].id);
          setSelectedTab(filteredCategories[0].id);
          setIsloading(false);
          setError("");
        } else {
          setIsloading(true);
          setError("No categories available");
          setShow(false)
        }
      } else {
        // Show all categories if selectedSquadron is not defined
        setFilterFaq(categoriesData);
        
        if (categoriesData.length > 0) {
          setShow(false)
          setDefaultQACategory(categoriesData[0].id);
          setSelectedTab(categoriesData[0].id);
          setIsloading(false);
          setError("");
        } else {
          setIsloading(true);
          setError("No categories available");

        }
      }
    } catch (error) {
      console.log("Error", error);
      setIsloading(true);
      setError("Error fetching categories");
    }
  };
  

  const getcategoriesQA = async (id) => {
    try {
      setCategoriesQA([]);
      setIsloading(true);
      const docRef = doc(db, "FAQs", id);

      const nestedCollectionRef = collection(docRef, "questions");
      const nestedQuerySnapshot = await getDocs(nestedCollectionRef);

      const nestedData = [];
      nestedQuerySnapshot.forEach((nestedDoc) => {
        const nestedItem = {
          question: nestedDoc.data().question,
          answer: nestedDoc.data().answer,
          link: nestedDoc.data().link || "No link available",
          id: nestedDoc.id,
        };
        nestedData.push(nestedItem);
      });

      setCategoriesQA((prev) => [...prev, ...nestedData]);

      setIsloading(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  // fetching squadron number
  const getSquadron = async () => {
    try {
      setSqdData([]);
      const res = query(collection(db, "squadrons"));
      onSnapshot(res, (snapShot) => {
        snapShot.forEach((obj) => {
          setSqdData((prev) => [
            ...prev,
            {
              squadron: obj.id,
            },
          ]);
        });
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleCategory = (category) => {
    setCategoriesQA([]);
    getcategoriesQA(category.replace(" ", ""));
  };

  const handleSquadron = (sqdNo) => {
    setSqdNo(sqdNo);
    // setShow(true);
    setSelectedSquadron(sqdNo);
    const filterCategory = faqCategories.filter((item) => {
      return item.squadronNo === sqdNo;
    });

    setFilterFaq(filterCategory);
    if (filterCategory.length > 0) {
      setShow(true)
      setDefaultQACategory(filterCategory[0].id);
      setError("");
      setIsloading(false)
    } else {
      setShow(false)
      setDefaultQACategory("");
      handleCategory("");
      setError("No Category Available");
      setIsloading(true)
    }
  };

  const handleDelete = async (id) => {
    try {
      setIsloading(true);
      const subcollectionQuery = query(
        collection(doc(db, "FAQs", id), "questions")
      );
      const subcollectionDocs = await getDocs(subcollectionQuery);

      subcollectionDocs.forEach(async (subDoc) => {
        await deleteDoc(doc(db, "FAQs", id, "questions", subDoc.id));
      });

      await deleteDoc(doc(db, "FAQs", id));
      refreshCategory();
      setIsloading(false);
    } catch (error) {
      console.error("Error deleting document and subcollection:", error);
    }
  };

  var check = false;
  useEffect(() => {
    setFaqCategories([]);
    setCategoriesQA([]);
    setFilterFaq([]);
    setSqdData([]);
    if (check === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
      getFaqCategories();
      getcategoriesQA();
      getSquadron();
    }
  }, []);

  useEffect(() => {
    if (defaultQACategory) {
      handleCategory(defaultQACategory);
    }
  }, [defaultQACategory]);

  const refreshCategory = () => {
    setFaqCategories([]);
    setCategoriesQA([]);
    setFilterFaq([]);
    setSqdData([]);
    getFaqCategories();
    // getcategoriesQA();
    getSquadron();
    setError("");
    // setShow(false);
  };

  return (
    <div className="mainDiv">
      <div>
        <Header />
      </div>
      <div>
        <p className="categoryTitle">Filter Categories</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#e3e9ee",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "80%" }}>
            <div className="filterBtnsDiv">
              <div className="filterBtns">
                {sqdData &&
                  sqdData.map((btn) => {
                    const isSelected = btn.squadron === selectedSquadron;
                    return (
                      <div key={btn.squadron}>
                        <button
                          className={isSelected ? "selected" : "notSelected"}
                          onClick={() => handleSquadron(btn.squadron)}
                        >
                          {btn.squadron}
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AddCategory
              sqdNo={sqdNo}
              loading={setIsloading}
              refreshCategory={refreshCategory}
            />
            {show && (
              <p
                className="addQuestion"
                style={{
                  borderRadius: "12px",
                  border: "1px solid #013191",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                <AddQuestionModal
                  buttonName={"Add Question"}
                  sqdNo={sqdNo}
                  refreshCategory={refreshCategory}
                />
              </p>
            )}
          </div>
        </div>
        <p style={{ paddingLeft: "1rem", marginTop: "1rem" }}>
          Following are the categories of FAQ:
        </p>
        <h3 style={{ textAlign: "center", color: "red" }}>
          {handleError && handleError}
        </h3>
        {isLoading ? (
          <div className="loader">
            <RotatingLines
              strokeColor="blue"
              strokeWidth="2"
              animationDuration="0.75"
              width="50"
              visible={true}
            />
          </div>
        ) : (
          <Grid
            container
            style={{ display: "flex", flexWrap: "wrap", paddingLeft: "1rem" }}
          >
            {/* categories mapping */}
            <Grid item lg={3} md={5} sm={5} xs={11}>
              <div className="categoriesDetail">
                {filterFaq &&
                  filterFaq.map((elem, i) => {
                    return (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCategory(elem.id);
                          setSelectedTab(elem.id);
                          faqData(elem.text, elem.id);
                          faqIdFun(elem.id);
                        }}
                        className={`faqDiv ${
                          selectedTab === elem.id
                            ? "selectedDiv"
                            : "notSelectedDiv"
                        }`}
                      >
                        <Categories category={elem} id={i} key={i} />
                        <AiFillDelete
                          size={20}
                          className={`${
                            selectedTab === elem.id
                              ? "selectedDelete"
                              : "notSelectedDelete"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(elem.id);
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            </Grid>
            {/* Q/A Mapping */}
            <Grid item lg={8} md={6} sm={6} xs={11} mt={1.5} ml={1}>
              <div className="categoryQA">
                {CategoriesQA &&
                  CategoriesQA.map((elem, i) => {
                    return (
                      <CategoriesQuestionAnswer
                        key={i}
                        refresh={refreshCategory}
                        {...elem}
                      />
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        )}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default FAQ;
