import { Typography } from '@mui/material'
import React from 'react'
import './Header.css'

function Header() {
  return (
      <div className='header'>
          <div className="headerText">
          <Typography variant='h3' style={{fontWeight:'500',letterSpacing:'4px'}}>Help Center</Typography>
          <Typography  variant='h5' style={{fontWeight:'300',letterSpacing:'3px'}}>The FAQs</Typography>
          <Typography variant='h5' style={{fontWeight:'300',letterSpacing:'3px'}}>Everything you need to know</Typography>
          </div>
    </div>
  )
}

export default Header