import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./addQuestionModal.css";
import { useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { getDocs, query, collection } from "firebase/firestore";
import db from "../Announcment/firebase";
// import Categories from './categories';
import GetFaqModal from "./GetFaqModal";
import { useAdminContext } from "../Context/contextStore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddQuestionModal({ buttonName,sqdNo,refreshCategory }) {
  const { faqIdFun } = useAdminContext();
  const [faqCategories, setFaqCategories] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  let check = false;
  useEffect(() => {
    setFaqCategories([]);
    if (check === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
      getFaqCategories();
    }
  console.log('calling with sqd',sqdNo)
  }, [sqdNo]);
  

  const getFaqCategories = async () => {
    try {
      setIsloading(true);
      const que = query(collection(db, "FAQs"));
      const snapShot = await getDocs(que);
      const categoriesData = [];
  
      snapShot.forEach((elem) => {
        const category = {
          text: elem.data().name,
          squadronNo: elem.data().squadronNo,
          id: elem.id,
        };
  
        categoriesData.push(category);
      });

      
      // Filter categories based on sqdNo, if provided
      const filteredCategories = sqdNo
      ? categoriesData.filter((item) => item.squadronNo === sqdNo)
      : categoriesData;
      setFaqCategories(filteredCategories);
      setIsloading(false);
    } catch (error) {
      console.log("Error", error);
    }
  };



  
  return (
    <StyledEngineProvider injectFirst>
      <div>
        <Button onClick={handleOpen}>{buttonName}</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="addQuestionModal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select Category
            </Typography>
            <div className="">
              {faqCategories?.map((Category, i) => {
                return (
                  <div
                    onClick={() => {
                       faqIdFun(Category.id);
                    }}
                    style={{
                      width: "100%",
                      border: "1px solid blue",
                      borderRadius: "12px",
                      marginBottom: "0.5rem",
                    }}
                    key={i}
                  >
                    <GetFaqModal
                      category={Category}
                      id={i}
                      key={i}
                      refreshData={refreshCategory}
                      close={handleClose}
                    />
                  </div>
                );
              })}
            </div>
          </Box>
        </Modal>
      </div>
    </StyledEngineProvider>
  );
}
