import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FaqModal from "./FaqModal";
import "./CategoriesQA.css";
import { useAdminContext } from "../Context/contextStore";

export default function CategoriesQuestionAnswer({
  question,
  answer,
  link,
  id,
  refresh
}) {
  const { category } = useAdminContext();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ padding: "4px" }}>
      <Accordion
        key={id}
        style={{ backgroundColor: "#40e0cf", overflow: "hidden" }}
        expanded={expanded === `panel${id}`}
        onChange={handleChange(`panel${id}`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="question">Question:{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="answer">Answer: {answer}</Typography>
          <Typography className="faqLink">Link: {link}</Typography>
        </AccordionDetails>
        <FaqModal
          buttonName={"Update"}
          question={question}
          answer={answer}
          link={link}
          docId={id}
          docName={category}
          refreshData={refresh}
        />
      </Accordion>
    </div>
  );
}
