export const OrderstatData = [
    {
      Name: "Coming Orders",
      Count: "45",
      Path: "/",
    },
    {
      Name: "Orders In Process",
      Count: "45",
      Path: "/",
    },
    {
      Name: "Completed Orders",
      Count: "45",
      Path: "/",
    },
   
  ];