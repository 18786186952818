import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getDocs, query, collection, doc, updateDoc } from "firebase/firestore";
import db from "../../Announcment/firebase";
import { useAdminContext } from "../../Context/contextStore";
import { Tooltip } from "@mui/material";

import { FcPositiveDynamic } from "react-icons/fc";
import { FcNegativeDynamic } from "react-icons/fc";


const roleStyle = {
    fontSize: "16px",
    fontWeight: '500',
    marginBottom:'5px'
};
const popStyle = {
  padding: "5px 10px",
    width: '170px',
    backgroundColor: 'rgb(240, 240, 241)'   
};

const Promotion = ({ button, checkOwner, userId,reloadFunc }) => {

    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedRole,setSelectedRole]=React.useState(' ')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    
    const roleFunc = (role) => {
        setSelectedRole(role)
    }

    const hanldeRole = async () => {
        handleClose();
        reloadFunc();
        try {
            const updateType = doc(db, "users", `${userId}`);
            await updateDoc(updateType, {
              type: selectedRole,
              designation:selectedRole
            });
          } catch (error) {
            console.log("Error",error)
          }
    }

  return (
    <div>
      <div>
      {

        (`${button}` === 'Promote') ?
          <Tooltip title="Promote" placement="top-start">
          <button onClick={handleClick} style={{marginRight:'10px',background:'transparent',border:'none'}}>
            <FcPositiveDynamic
              color="#0465db"
              size={30}
              className="tick"
            />
          </button>
          </Tooltip>
          : (`${button}` === 'Demote') ? <Tooltip title="Demote" placement="top-start">
               
            <button onClick={handleClick} style={{marginRight:'10px',background:'transparent',border:'none'}}>
                  <FcNegativeDynamic
                    color="#0465db"
                    size={30}
                    className="tick"
                    />
                    </button>
              </Tooltip> :  null
        }
        </div>
      
      

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
              <div style={popStyle}>
                  
                  <p style={roleStyle}>Assign New Role </p>
                  {
                      checkOwner? <div><input type="radio" id="owner" value="Owner" name="role" onChange={(e)=>roleFunc(e.target.value)} className="promotionInput"/>
                      <label For="owner" className="promotionLabel">Owner</label><br/></div> : <div><input type="radio" id="normal" value="Normal" name="role" onChange={(e)=>roleFunc(e.target.value)} className="promotionInput"/>
                      <label For="normal" className="promotionLabel">Normal</label><br/></div>
                  }
                  
                  <input type="radio" id="tcadmin" value="TcAdmin" name="role" className="promotionInput" onChange={(e) => roleFunc(e.target.value)} />
                  <label For="tcadmin" className="promotionLabel">TcAdmin</label><br/>
                  <input type="radio" id="ddadmin" value="DdAdmin" name="role" className="promotionInput" onChange={(e) => roleFunc(e.target.value)} />
                  <label For="ddadmin" className="promotionLabel">DdAdmin</label><br />
                  <button className="pdBtn" style={{marginTop:'10px'}} onClick={hanldeRole}>{button}</button>
        </div>
      </Popover>
    </div>
  );
};

export default Promotion;
