import * as React from "react";
import Popover from "@mui/material/Popover";
import {
  collection,
  doc,
  setDoc,
} from "firebase/firestore";
import db from "../../Announcment/firebase";
import { Tooltip } from "@mui/material";

import {VscRequestChanges} from "react-icons/vsc"

const roleStyle = {
  fontSize: "16px",
  fontWeight: "500",
  marginBottom: "5px",
};
const popStyle = {
  padding: "5px 10px",
  width: "250px",
  backgroundColor: "rgb(240, 240, 241)",
};

const PromotionRequest = ({ button, userId, reloadFunc,isOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState(" ");
  const [reqDetail, setReqDetail] = React.useState(" ");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const roleFunc = (role) => {
    setSelectedRole(role);
  };

  const hanldeRole = async () => {
    handleClose();
    try {
      await setDoc(doc(collection(db, "Requests"), userId), {
        post: selectedRole,
        reqDetail: reqDetail,
        type:"Promotion",
        status: "unread",
      });
      reloadFunc();
      console.log("Send Request");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <div  onClick={handleClick} style={{display:'flex',alignItems:"center",width:"100%",paddingLeft:'12px'}}>
     <Tooltip title="Request" placement="top-start">
      <button style={{marginTop:'8px',background:'none',border:'none'}}>
                     <VscRequestChanges
                       color="white"
                       size={25}
                       className="tick"
                       />
      </button>
        </Tooltip> 
        {
          isOpen && <p className="req" style={{color:'white'}}>Request</p>
        }
                       </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={popStyle}>
          <p style={roleStyle}>Select New Role </p>

          <input
            type="radio"
            id="owner"
            value="Owner"
            name="role"
            onChange={(e) => roleFunc(e.target.value)}
            className="promotionInput"
          />
          <label For="owner" className="promotionLabel">
            Owner
          </label>
          <br />
          <input
            type="radio"
            id="tcadmin"
            value="TcHeadAdmin"
            name="role"
            className="promotionInput"
            onChange={(e) => roleFunc(e.target.value)}
          />
          <label For="tcadmin" className="promotionLabel">
            TcHeadAdmin
          </label>
          <br />
          <input
            type="radio"
            id="tcheadadmin"
            value="TcAdmin"
            name="role"
            className="promotionInput"
            onChange={(e) => roleFunc(e.target.value)}
          />
          <label For="tcadmin" className="promotionLabel">
            TcAdmin
          </label>
          <br />
          <input
            type="radio"
            id="ddadmin"
            value="DdAdmin"
            name="role"
            className="promotionInput"
            onChange={(e) => roleFunc(e.target.value)}
          />
          <label For="ddadmin" className="promotionLabel">
            DdAdmin
          </label>
          <br />
          <textarea
            rows="3"
            cols="25"
            placeholder="Request Details"
            onChange={(e) => setReqDetail(e.target.value)}
          ></textarea>
          <button
            className="pdBtn"
            style={{ marginTop: "10px" }}
            onClick={hanldeRole}
          >
            {button}
          </button>
        </div>
      </Popover>
    </div>
  );
};

export default PromotionRequest;
