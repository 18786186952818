import * as React from "react";
import Popover from "@mui/material/Popover";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  where,
  query,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import db from "../../Announcment/firebase";
import { useState } from "react";
import {FaShareAlt} from 'react-icons/fa'

export default function AddSquadron({refreshData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [squadron, setSquadron] = useState("");
  const [builds, setBuilds] = useState([]);
  const [addBuildMode, setAddBuildMode] = useState(false);

 

  const handleInput = (e) => {
    setSquadron(e.target.value);
  };

  const handleBuild = async () => {
    try {
      const docRef = doc(collection(db, "squadrons"), squadron);
      const buildData = {};
      builds.forEach((build, index) => {
        buildData[index + 1] = build;
      });

      await setDoc(docRef, buildData);

      handleClose();
      refreshData();
    } catch (error) {
      console.log("Error adding new squadron:", error);
    }
  };

  const handleAddBuild = () => {
    setAddBuildMode(true);
  };

  const handleAddBuildNumber = (e) => {
    e.preventDefault();
    const newBuild = e.target.buildNumber.value;
    setBuilds([...builds, newBuild]);
    e.target.reset();
  };



  return (
    <div>
      <button
        aria-describedby={id}
        className="addSquadronBtn"
        onClick={handleClick}
      >
        Add Squadron
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        
        <div className="BuildMain">
          <p className="squadronTitle" >
            Add New Squadron
            <FaShareAlt size={18} color="#013191" style={{marginLeft:'5px'}}/>
          </p>
          <input
            type="text"
            placeholder="New Squadron"
            onChange={handleInput}
            className="buildInput"
          />
          
          <button className="addSquadronBtn" onClick={handleBuild}>
            Add Squadron
          </button>
        </div>
      </Popover>
    </div>
  );
}
