import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AnnImg from "../../../assets/announcment1.png";
import { useState, useEffect } from "react";
import "../Announcment.css";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";
import db from "../firebase";
import { HiOutlineUpload } from "react-icons/hi";
import Select from "react-select";
import { GrFormClose } from "react-icons/gr";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  backgroundColor: "#e3e9ee",
  p: 4,
  borderRadius: "12px",
};

const addDiv = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // width: "230px",
};

const imgStyle = {
  height: "25px",
  width: "25px",
  background: "transparent",
  marginLeft: "10px",
};

export default function AddAnnouncement({ refreshData }) {
  const [squadron, setSquadron] = useState(null);
  const [comingSquadron, setComingSquadron] = useState([]);
  const [announcement, setAnnouncemnt] = useState({
    name: "",
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // fetching squadron
  const getSquadron = async () => {
    try {
      setSquadron();
      const res = query(collection(db, "squadrons"));
      onSnapshot(res, (snapshot) => {
        snapshot.forEach((sqd) => {
          setComingSquadron((prev) => [...prev, { squadronNo: sqd.id }]);
        });
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const options = [
    { label: "All", value: "all" },
    ...comingSquadron.map((obj) => ({
      label: obj.squadronNo,
      value: obj.squadronNo,
    })),
  ];

  let check = false;
  useEffect(() => {
    if (check === false) {
      getSquadron();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
    }
  }, []);

  // Sending Announcment to Database
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (announcement.name.length > 0 && squadron) {
      try {
        // const squadronValue = squadron ? squadron.value : null;
        const res = await addDoc(collection(db, "Announcements"), {
          text: announcement.name,
          squadronNo: squadron.value,
          date: new Date().toLocaleDateString(),
        });
        handleClose();
        setSquadron(null);
        refreshData();
        console.log("Document written");
      } catch (e) {
        console.error("Error adding document: ", e);
      }

      setAnnouncemnt({
        name: "",
      });
    } else {
      alert("Please Add Both Fields");
    }
  };

  // Catch input Announcmnet

  const AnnoucFunc = (e) => {
    setAnnouncemnt({
      ...announcement,
      name: e.target.value,
    });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "225px", // Set the desired width for the main container
      borderRadius: "8px",
      padding: "3px",
      marginTop: "10px",
      border: "0",
    }),
    menu: (provided) => ({
      ...provided,
      width: "225px", // Set the desired width for the menu list
    }),
    option: (provided, state) => ({
      ...provided,
      width: "225px",
    }),
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <div style={addDiv} className="addDiv">
          <h2 className="AnnouncementTitle">Announcements</h2>
          <img src={AnnImg} alt="annImg" style={imgStyle} />
        </div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="iconDiv">
            <GrFormClose size={20} className="icon" onClick={handleClose} />
          </div>
          <div className="form">
            <div className="labelAndField">
              <form onSubmit={handleSubmit}>
                <textarea
                  id="name"
                  name="name"
                  className="textInput"
                  value={announcement.name}
                  placeholder="Add Announcement"
                  onChange={AnnoucFunc}
                  rows={4}
                />
                <div className="selectSquadron">
                  <Select
                    options={options}
                    onChange={setSquadron}
                    styles={customStyles}
                    isClearable={true}
                  />
                </div>
                <div className="button">
                  <button className="addBtn">
                    Add{" "}
                    <HiOutlineUpload
                      size={20}
                      color="white"
                      style={{ paddingLeft: "5px" }}
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
