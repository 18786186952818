import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAdminContext } from "../../../pages/Context/contextStore";
import { Button, TextField, Tooltip } from "@mui/material";
import { getAuth } from "firebase/auth";
import db from "../../../pages/Announcment/firebase";
import { getDoc, query, collection, doc, updateDoc } from "firebase/firestore";
import { FaUserEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  //   border: "2px solid #000",
  backgroundColor: "#e3e9ee",
  boxShadow: 24,
  borderRadius: "12px",
  outline: "none",
  p: 4,
};

function UpdateUser({ data, refresh }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [userData, setUserData] = React.useState({
    name: data.name,
    squadronNo: data.squadronNo,
    buildingNo: data.buildingNumber,
  });

  const updateData = async () => {
    try {
      const updateRef = doc(db, "users", `${data.username}`);
      await updateDoc(updateRef, {
        name: userData.name,
        buildingNumber: userData.buildingNo,
        squadronNo: userData.squadronNo,
      });
      updateToast();
      refresh();
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleName = (e) => {
    setUserData({
      ...userData,
      name: e.target.value,
    });
  };
  const handleSquadron = (e) => {
    setUserData({
      ...userData,
      squadronNo: e.target.value,
    });
  };
  const handleBuilding = (e) => {
    setUserData({
      ...userData,
      buildingNo: e.target.value,
    });
  };
  

  const btnStyle = {
    fontWeight: " 600",
    fontSize: "large",
    padding: " 0.5rem 0.5rem",
  };

  const updateProfileText = {
    fontSize: "large",
    fontWeight: " 500",
    letterSpacing: "1px",
  };
  const mainInputDiv = {
    marginTop: "10px",
  };
  const inputField = {
    margin: "5px 0",
  };

  const updateToast = () => {
    toast("Profile Updated", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  return (
    <div>
      <Tooltip title="Edit Profile" placement="top-start">
        <Button onClick={handleOpen}>
          <FaUserEdit size={30} className="tick" />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p style={updateProfileText}>Update User's Profile</p>
          <div style={mainInputDiv}>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              style={inputField}
              onChange={(e) => handleName(e)}
              value={userData.name}
            />
            <TextField
              id="outlined-basic"
              label="Squadron Number"
              variant="outlined"
              style={inputField}
              onChange={(e) => handleSquadron(e)}
              value={userData.squadronNo}
            />
            <TextField
              id="outlined-basic"
              label="Building Number"
              variant="outlined"
              style={inputField}
              onChange={(e) => handleBuilding(e)}
              value={userData.buildingNo}
            />
            
          </div>
          <button
            className="updateProfileBtn"
            onClick={() => {
              updateData();
              handleClose();
            }}
          >
            Update
          </button>
        </Box>
      </Modal>
      <ToastContainer/>
    </div>
  );
}
export default UpdateUser;
