import React, { useEffect, useState } from "react";
import "./User.css";
import { getDocs, query, collection, doc, deleteDoc } from "firebase/firestore";
import db from "../Announcment/firebase";
import Tooltip from "@mui/material/Tooltip";
import { Button, TextField } from "@mui/material";
import { BiReset } from "react-icons/bi";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Footer from "../../components/Footer/Footer";
import { useAdminContext } from "../Context/contextStore";
import { RotatingLines } from "react-loader-spinner";
import Promotion from "./promotDemot/promotDemot";
import Notification from "./promotDemot/notification";
import UpdateUser from "./updateUser/updateUser";
import axios from "axios";
import Squadrons from "./Squadron/Squadron";

function User() {
  const { verifyOwner, verifyOwnerFunc } = useAdminContext();
  const [userDetails, setUserDetails] = useState([]);
  const [filteredUser, setfilteredUser] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");

  const auth = getAuth();
  const triggerResetEmail = async (email) => {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent");
  };

  var check = false;
  useEffect(() => {
    verifyOwnerFunc(
      localStorage.getItem("owner"),
      localStorage.getItem("name")
    );
    setfilteredUser([]);
    setUserDetails([]);
    if (check === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
      getUser();
    }
  }, []);

  // deleting user from fireStore
  const deleteDocument = async (email) => {
    const docRef = doc(db, "users", `${email}`);

    try {
      await deleteDoc(docRef);
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  // delete
  const deleteUsers = async (email) => {
    try {
      const response = await axios.post(
        "https://us-central1-scp-302a2.cloudfunctions.net/deleteUserByEmail",
        { userEmail: email }
      );
      deleteDocument(email);
      reloadTable();
      // console.log("user deleted",response)
    } catch (error) {
      console.error("Error deleting user:", error.message);
    }
  };

  // reload table function
  const reloadTable = () => {
    setfilteredUser([]);
    setUserDetails([]);
    getUser();
  };

  // fething user data from database
  const getUser = async () => {
    try {
      setIsloading(true);
      setUserDetails([]);
      setfilteredUser([])
      const que = query(collection(db, "users"));
      const snapShot = await getDocs(que);
      snapShot.forEach((elem) => {
        setUserDetails((prev) => [
          ...prev,
          {
            name: elem.data().name,
            buildingNumber: elem.data().buildingNumber,
            squadronNo: elem.data().squadronNo,
            role: elem.data().role,
            username: elem.data().username,
            id: elem.id,
          },
        ]);

        setfilteredUser((prev) => [
          ...prev,
          {
            name: elem.data().name,
            buildingNumber: elem.data().buildingNumber,
            squadronNo: elem.data().squadronNo,
            role: elem.data().role,
            username: elem.data().username,
            id: elem.id,
          },
        ]);
        setIsloading(false);
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  // Mapping feedbacks data in table
  let UserTableData = filteredUser?.map((elem, i) => {
    return (
      <>
        <tr key={i} onClick={() => {}}>
          <td className="idU">{i === 0 ? i + 1 : i + 1}</td>
          <td className="nameU">{elem.name}</td>
          <td className="designationU">{elem.role}</td>
          <td className="addressU">{elem.squadronNo}</td>
          <td className="addressU">{elem.buildingNumber}</td>

          <td className="actionsU">
            <span className="ActioniconUser">
              {verifyOwner && (
                <div className="promotDemot">
                  <Promotion
                    button={"Promote"}
                    checkOwner={true}
                    userId={elem.email}
                    reloadFunc={reloadTable}
                  />
                  <Promotion
                    button={"Demote"}
                    checkOwner={false}
                    userId={elem.email}
                    reloadFunc={reloadTable}
                  />
                </div>
              )}

              {/* <Tooltip title="Reset Password" placement="top-start">
                <Button>
                  <BiReset
                    color="#0465db"
                    size={30}
                    className="tick"
                    onClick={() => {
                      triggerResetEmail(elem.email);
                    }}
                  />
                </Button>
              </Tooltip> */}

              <Tooltip title="Delete" placement="top-start">
                <Button>
                  <RiDeleteBin5Fill
                    size={30}
                    className="tick"
                    onClick={() => {
                      deleteUsers(elem.email);
                    }}
                  />
                </Button>
              </Tooltip>
              {verifyOwner ? (
                <UpdateUser data={elem} refresh={reloadTable} />
              ) : null}
            </span>
          </td>
        </tr>
      </>
    );
  });

  // All filters function
  const filterAllUser = () => {
    setfilteredUser(userDetails);
    setSelectedTab("All");
  };
  const filterNormalUser = () => {
    const normal = userDetails.filter((elem) => {
      return elem.role.toLowerCase() === "normal";
    });
    setfilteredUser(normal);
    setSelectedTab("User");
  };
  const filterAdmin = () => {
    const admin = userDetails.filter((elem) => {
      return elem.role.toLowerCase() === "admin";
    });
    setfilteredUser(admin);
    setSelectedTab("Admin");
  };
  const filterOwner = () => {
    const owner = userDetails.filter((elem) => {
      return elem.role.toLowerCase() === "owner";
    });
    setfilteredUser(owner);
    setSelectedTab("Owner");
  };

  // search Box filter function
  const handleSearchBox = (e) => {
    const res = userDetails.filter((elem) => {
      return (
        elem.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        elem?.role?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        elem?.squadronNo?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        elem?.buildingNumber?.toLowerCase().includes(e.target.value.toLowerCase()) 
      );
    });
    setfilteredUser(res);
  };
  return (
    <div className="mainDivUser">
      <div className="refreshUser">
        <p className="userTitle">Application Users Record</p>
        <div style={{ display: "flex", paddingTop: "10px" }}>
          <div>{verifyOwner ? <Squadrons /> : null}</div>
          <div
            style={{
              marginRight: "20px",
              cursor: "pointer",
              marginBottom: "5px",
            }}
          >
            {verifyOwner ? <Notification /> : null}
          </div>
        </div>
      </div>
      <div className="searchBoxandTabsU">
        <div className="tabsMainf">
          <div>
            <p
              className={`tabBtnU ${
                selectedTab === "All" ? "selectedU" : "notSelectedU"
              }`}
              onClick={() => {
                filterAllUser();
              }}
            >
              All
            </p>
          </div>
          <div>
            <p className={`tabBtnU ${
                selectedTab === "User" ? "selectedU" : "notSelectedU"
              }`} onClick={() => filterNormalUser()}>
              Users
            </p>
          </div>
          <div>
            <p className={`tabBtnU ${
                selectedTab === "Admin" ? "selectedU" : "notSelectedU"
              }`} onClick={() => filterAdmin()}>
              Admin
            </p>
          </div>
          <div>
            <p className={`tabBtnU ${
                selectedTab === "Owner" ? "selectedU" : "notSelectedU"
              }`} onClick={() => filterOwner()}>
              Owner
            </p>
          </div>
        </div>
        <div
          className="searchBox"
          style={{
            marginBottom: "0.5rem",
            borderRadius: "12px",
            lineHeight: "1em",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search User"
            variant="outlined"
            onChange={(e) => handleSearchBox(e)}
            style={{
              width: "19rem",
              borderRadius: "12px",
              lineHeight: "1em",
            }}
            className="userSearchBox"
          />
        </div>
      </div>
      {isLoading ? (
        <div className="loader">
          <RotatingLines
            strokeColor="blue"
            strokeWidth="2"
            animationDuration="0.75"
            width="50"
            visible={true}
          />
        </div>
      ) : (
        <div className="userDetails">
          <table id="orderTable" className="default">
            <thead>
              <tr>
                <th>ID </th>
                <th>Name</th>
                <th>Designation</th>
                <th>SquadronNo</th>
                <th>BuildingNo</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{UserTableData}</tbody>
          </table>
        </div>
      )}

      <div id="userFooter">
        <Footer />
      </div>
    </div>
  );
}

export default User;
