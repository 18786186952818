import React from 'react'
import {Route, Routes} from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Feedbacks from "../pages/FeedBacks/Feedbacks";
import Orders from "../pages/Orders/Orders";
import FAQ from "../pages/FAQ/FAQ";
import Login from "../pages/Login/Login";
// import SignUp from "./pages/Signup/SignUp";
import User from "../pages/Users/User";
import { useAdminContext } from "../pages/Context/contextStore";
import MobileNavBar from '../components/MobileNavBar/MobileNavBar';

const AuthenticatedRoutes = () => {
  const { isAuthenticated,verifyDDAdmin,verifyTCAdmin,verifyTCHeadAdmin} = useAdminContext();

    if (verifyTCAdmin||verifyTCHeadAdmin) {
      return (
        <Routes>
          {isAuthenticated ? (
            <Route path="/comments" element={<Feedbacks />} />
          ) : (
            <Route path="/comments" element={<Login />} />
          )}
          
        </Routes>
      );
    } else if (verifyDDAdmin) {
      return (
        <Routes>
          {isAuthenticated ? (
            <Route path="/orders" element={<Orders />} />
          ) : (
            <Route path="/orders" element={<Login />} />
          )}
          
        </Routes>
      );
    } else {
      return (
        <Routes>
          {isAuthenticated ? (
            <Route path="/" element={<Dashboard />} />
          ) : (
            <Route path="/" element={<Login />} />
          )}
          {isAuthenticated ? (
            <Route path="/login" element={<Dashboard />} />
          ) : (
            <Route path="/login" element={<Login />} />
          )}
          {isAuthenticated ? (
            <Route path="/dashboard" element={<Dashboard />} />
          ) : (
            <Route path="/dashboard" element={<Login />} />
          )}
          {isAuthenticated ? (
            <Route path="/comments" element={<Feedbacks />} />
          ) : (
            <Route path="/comments" element={<Login />} />
          )}
          {isAuthenticated ? (
            <Route path="/orders" element={<Orders />} />
          ) : (
            <Route path="/orders" element={<Login />} />
          )}
          {isAuthenticated ? (
            <Route path="/faq" element={<FAQ />} />
          ) : (
            <Route path="/faq" element={<Login />} />
          )}
          {isAuthenticated ? (
            <Route path="/users" element={<User />} />
          ) : (
            <Route path="/users" element={<Login />} />
          )}
          {/* <Route path="/signup" element={<SignUp />} /> */}
        </Routes>
        
          
      );
    }
  };
  
  export default AuthenticatedRoutes;
  
  
  