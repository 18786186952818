import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OrderstatData } from "./OrderStatsData";
import { CommentsstatData } from "./FeedbackStats";
import "./stats.css";
import {
  collection,
  query,
  getDocs,
} from "firebase/firestore";
import db from "../Announcment/firebase";

function Stats() {
  const [Ordercards, setOrderCards] = useState(OrderstatData);
  const [Feedbackcards, setFeedbackCards] = useState(CommentsstatData);
  const [OrderCountArray, setOrderCountArray] = useState([]);
  const [FeedbackCountArray, setFeedbackCountArray] = useState([]);

  var check = false;
  useEffect(() => {
    if (check === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
      getOrders();
      getFeedBacks();
    }
  }, []);

  // variable to store order counts
  var CompletedOrderCount = 0;
  var RejectedOrderCount = 0;
  var ProcessingOrderCount = 0;
  var NewOrderCount = 0;
  var TotalOrderCount = 0;

  // fetching orders and get count from database
  const getOrders = async () => {
    const que = query(collection(db, "Orders"));
    const querySnapshot = await getDocs(que);
    querySnapshot.forEach((elem) => {
      if (elem) {
        TotalOrderCount = TotalOrderCount + 1;
      }
      if (elem.data().Status === "Completed") {
        CompletedOrderCount = CompletedOrderCount + 1;
      } else if (elem.data().Status === "Deleted") {
        RejectedOrderCount = RejectedOrderCount + 1;
      } else if (elem.data().Status === "Processing") {
        ProcessingOrderCount = ProcessingOrderCount + 1;
      } else {
        let sum =
          CompletedOrderCount + RejectedOrderCount + ProcessingOrderCount;
        NewOrderCount = TotalOrderCount - sum;
      }

      setOrderCountArray([
        NewOrderCount,
        ProcessingOrderCount,
        CompletedOrderCount,
        RejectedOrderCount,
      ]);
    });
  };

  // variable to store feedbacks counts
  var CompletedFeedabackCount = 0;
  var RejectedFeedabackCount = 0;
  var ProcessingFeedabackCount = 0;
  var NewFeedabackCount = 0;
  var TotalFeedabackCount = 0;

  // fetching feedbacks and get count from database
  const getFeedBacks = async () => {
    const feedbacks = query(collection(db, "Suggestions"));
    const snapShot = await getDocs(feedbacks);
    snapShot.forEach((elem) => {
      if (elem) {
        TotalFeedabackCount = TotalFeedabackCount + 1;
      }
      if (elem.data().Status === "Completed") {
        CompletedFeedabackCount = CompletedFeedabackCount + 1;
      } else if (elem.data().Status === "Deleted") {
        RejectedFeedabackCount = RejectedFeedabackCount + 1;
      } else if (elem.data().Status === "Processing") {
        ProcessingFeedabackCount = ProcessingFeedabackCount + 1;
      } else {
        let sum =
          CompletedFeedabackCount +
          RejectedFeedabackCount +
          ProcessingFeedabackCount;
        NewFeedabackCount = TotalFeedabackCount - sum;
      }

      setFeedbackCountArray([
        NewFeedabackCount,
        ProcessingFeedabackCount,
        CompletedFeedabackCount,
        RejectedFeedabackCount,
      ]);
    });
  };

  return (
    <div className="stats">
      {/* Orders-Stats */}
      {FeedbackCountArray && OrderCountArray ? (
        <div>
          <Grid container rowGap={1}>
            {Ordercards.map((card, i) => {
              return (
                <Grid item xs={12} sm={10} md={6} lg={4} key={i}>
                  <div className="row" >
                    <Typography variant="h4" mb={1}>
                      {card.Name}
                    </Typography>
                    <div className="numberAndArrow">
                      <p>{OrderCountArray[i]}</p>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <Grid container rowGap={1}>
            {Feedbackcards?.slice(0, 3).map((card, i) => {
              return (
                <Grid item xs={12} sm={10} md={6} lg={4} key={i}>
                  <div className="row">
                    <Typography variant="h4" mb={1}>
                      {card.Name}
                    </Typography>
                    <div className="numberAndArrow">
                      <p>{FeedbackCountArray[i]}</p>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        <div>
          <h1>Loading....</h1>
        </div>
      )}
    </div>
  );
}

export default Stats;
