import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import Footer from "../../components/Footer/Footer";
import Announcment from "../Announcment/Announcment";
import Stats from "../Stats/Stats";

function Dashboard() {
 
  return (
    <div className="masterClass">
      <div className="containerD">
        {/* Stats-Section */}
        <Stats/>
        {/* Stats-Section-Ended */}
          {/* Announcment Section start*/}
        <Announcment/>
         {/* Announcment Section end*/}
      </div>
      <Footer/>
    </div>
  );
}

export default Dashboard;
