import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import db from "../../Announcment/firebase";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  where,
  query,
  onSnapshot,
  FieldValue,
  deleteField,
} from "firebase/firestore";
import { RiDeleteBin5Fill, RiBuilding2Line } from "react-icons/ri";
import AddBuild from "./addBuild";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({
  squadron,
  buildNumber,
  refreshData,
}) {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDelete = async (id) => {
    try {
      const deleteId = doc(db, "squadrons", id);
      await deleteDoc(deleteId);
      refreshData();
    } catch (error) {
      console.log("Error", error);
    }
  };

  const deleteBuilding = async (id, value, fieldName) => {
    try {
      const documentRef = doc(db, "squadrons", id);
      const updateData = {
        [fieldName]: null,
      };
      await updateDoc(documentRef, updateData);
      refreshData();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const mainSqaudron = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px",
  };

  return (
    <div>
      <Accordion
        expanded={expanded === `${squadron}`}
        onChange={handleChange(`${squadron}`)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div style={mainSqaudron} className="mainSqaudron">
            <p style={{ fontSize: "19px", fontWeight: "500" }}>{squadron}</p>
            <div>
              {/* <MdAutorenew size={25} color="#013191" /> */}
              <RiDeleteBin5Fill
                size={25}
                color="#013191"
                style={{ cursor: "pointer", zIndex: "5" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(squadron);
                }}
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {buildNumber && (
            <div className="build" id={squadron}>
              {buildNumber &&
                Object.entries(buildNumber).map(([index, value]) => {
                  if (value !== null) {
                    return (
                      <div className="mainBuiling">
                        <p className="buildNumber">
                          <RiBuilding2Line
                            size={20}
                            style={{ marginRight: "5px" }}
                            color="#013191"
                          />
                          {value && value}
                        </p>
                        <RiDeleteBin5Fill
                          size={20}
                          color="#013191"
                          style={{ cursor: "pointer", zIndex: "5" }}
                          onClick={() => deleteBuilding(squadron, value, index)}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          )}
          <div className="addBuild">
            <AddBuild sqdId={squadron} buildObject={buildNumber} refreshData={refreshData} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
