import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      {/* <main>{children}</main> */}
      <h1 className="footerContent">Squadron Ally</h1>
      <h2 className="footerContent">Download Mobile App Now</h2>
      <div className="storeLogoRow">
        <a href="https://www.google.com" target="_blank">
          <img
            style={{ width: 165,padding:3}}
            src={require("../../assets/google-play.png")}
            alt="Google Play Logo"
            srcset=""
            onClick={"https://www.google.com"}
          />
        </a>
        <a href="https://www.google.com" target="_blank">
          <img
            style={{ width: 151}}
            src={require("../../assets/app-store.png")}
            alt="Google Play Logo"
            srcset=""
            onClick={"https://www.google.com"}
          />
        </a>
        <p className="footerContent" style={{fontSize:'medium'}}>Ⓒ Squadron Ally. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
