import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Input, StyledEngineProvider, TextField } from "@mui/material";
import "./AddCategory.css";
import { addDoc, collection, doc } from "firebase/firestore";
import db from "../../Announcment/firebase";
import { useAdminContext } from "../../Context/contextStore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  backgroundColor: "#e3e9ee",
};

export default function AddCategory({ sqdNo,loading,refreshCategory }) {
  const [open, setOpen] = React.useState(false);
  const [faqValue, setFaqValue] = React.useState({
    description: "",
    name: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // handle inputs
  const handleDescription = (e) => {
    setFaqValue({
      ...faqValue,
      description: e.target.value,
    });
  };
  const handleName = (e) => {
    setFaqValue({
      ...faqValue,
      name: e.target.value,
    });
  };
 

  // // sending FAQ to firestore
  // function reloadPage() {
  //   window.location.reload(true);
  // }

  const sendFaqToDB = async () => {
    const { description, name} = faqValue;

    if (sqdNo === "" || sqdNo === undefined) {
      alert("Please select squadron first");
      setFaqValue({
        description: "",
        name: "",
      });
    } else {
      if (description && name) {
        try {
          loading(true);
          const res=await addDoc(collection(db, "FAQs"), {
            description: description,
            name: name,
            squadronNo: sqdNo,
          });

          // Reset the input values
          if (res) {
            setFaqValue({
              description: "",
              name: "",
            });
            loading(false);
            refreshCategory()
          }
        } catch (error) {
          console.log("Error", error);
        }
      } else {
        alert("Please add all credentials");
        handleOpen();
      }
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <div>
        <Button onClick={handleOpen} className="CategoryBtn">
          Add Category
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              style={{ fontWeight: "600", color: "#013191", margin: "10px 0" }}
            >
              Add Category
            </Typography>
            <TextField
              id="outlined-basic"
              label="Add Description"
              variant="outlined"
              value={faqValue.description}
              onChange={(e) => handleDescription(e)}
              style={{
                width: "19rem",
                borderRadius: "12px",
                lineHeight: "1em",
                marginBottom: "0.5rem",
              }}
            />
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={faqValue.name}
              onChange={(e) => handleName(e)}
              style={{
                width: "19rem",
                borderRadius: "12px",
                lineHeight: "1em",
                marginBottom: "0.5rem",
              }}
            />
            

            <button
              className="addFaq"
              onClick={() => {
                handleClose();
                sendFaqToDB();
              }}
            >
              Submit
            </button>
          </Box>
        </Modal>
      </div>
    </StyledEngineProvider>
  );
}
