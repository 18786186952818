import { Typography } from "@mui/material";
import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";

function Categories({ category, id }) {
  const handleDelete = (id) => {
    alert("deleted", category.id);
  };

  return (
    <div>
      <div key={id}>
        {category.text}
        {/* <AiFillDelete size={20} color="blue" onClick={()=>handleDelete(category.id)}/> */}
      </div>
    </div>
  );
}

export default Categories;
