/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { FaTh, FaBars, FaQuestionCircle, FaPowerOff } from "react-icons/fa";
import { BsArrowLeftCircle } from "react-icons/bs";
import { ImCart } from "react-icons/im";
import { MdAdminPanelSettings } from "react-icons/md";
import { MdFeedback } from "react-icons/md";
import { NavLink } from "react-router-dom";
import "./SideBar.css";
import { getAuth } from "firebase/auth";
import { useAdminContext } from "../../pages/Context/contextStore";
import { Tooltip } from "@mui/material";
import UpdateProfile from "./UpdateProfile/UpdateProfile";
import PromotionRequest from "../../pages/Users/promotDemot/promotionRequest";

const SideBar = ({ children }) => {
  const {
    signOut,
    verifyOwner,
    isAuthenticated,
    toggleSideBar,
    setToggleSideBar,
    verifyDDAdmin,
    verifyTCAdmin,
    verifyTCHeadAdmin
  } = useAdminContext();
  const auth = getAuth();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/Orders",
      name: "Orders",
      icon: <ImCart />,
    },
    {
      path: "/comments",
      name: "Comments",
      icon: <MdFeedback />,
    },
    {
      path: "/FAQ",
      name: "FAQ",
      icon: <FaQuestionCircle />,
    },
    {
      path: "/users",
      name: "Users",
      icon: <MdAdminPanelSettings />,
    },
  ];

  
  const userRole = localStorage.getItem("owner");
  const currentUser = localStorage.getItem("username");

  const signOutFunc = async () => {
    try {
      await signOut(auth).then(() => {
        signOut();
        // goToSignUp();
        localStorage.removeItem("name");
        localStorage.removeItem("current");
        localStorage.removeItem("owner");
        localStorage.removeItem("sqdNo");
        localStorage.removeItem("verifyOwner");
        localStorage.removeItem("verifytcAdmin");
        localStorage.removeItem("verifyddAdmin");
        localStorage.removeItem("verifytcheadAdmin");
        Reload();
      });
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  // window reload function
  const Reload = () => {
    window.location.reload(true);
  };

  return (
    <div className="container">
      {isAuthenticated && (
        <div
          style={{ width: isOpen ? "230px" : "50px" }}
          className={toggleSideBar ? "sidebar1" : "sidebar"}
        >
          <div className="topSection">
            <img
              style={{
                width: 50,
                borderRadius: 10,
                display: isOpen ? "block" : "none",
              }}
              src={require("../../assets/logo.jpg")}
              alt="logoImage"
            />
            <p
              style={{
                display: isOpen ? "block" : "none",
              }}
              className="logo"
            >
              Squadron Ally
            </p>
            <div className="bars">
              {isOpen ? (
                <BsArrowLeftCircle
                  className="backArrow"
                  onClick={toggle}
                  onMouseOver={({ target }) => (target.style.color = "#40e0cf")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              ) : (
                <FaBars
                  onClick={toggle}
                  onMouseOver={({ target }) => (target.style.color = "#40e0cf")}
                  onMouseOut={({ target }) => (target.style.color = "white")}
                />
              )}
            </div>
          </div>

          {menuItem.map((item, index) => {
            if (
              (verifyDDAdmin && item.name === "Orders") ||
              (verifyTCAdmin && item.name === "Comments") ||
              (verifyTCHeadAdmin && item.name === "Comments") ||
              userRole === "owner"
            ) {
              return (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link"
                  onClick={() => setIsOpen(false)}
                  activeClassName="active"
                >
                  <Tooltip title={item.name} placement="right-start">
                    <div className="icon">{item.icon}</div>
                  </Tooltip>
                  <div
                    style={{ display: isOpen ? "block" : "none" }}
                    className="link_text"
                  >
                    {item.name}
                  </div>
                </NavLink>
              );
            } else {
              return null; // Don't render the menu item
            }
          })}
          <div>
            <div className="userLogin">
              {isOpen ? (
                <div>
                  <div className="user">
                    <UpdateProfile />
                  </div>
                  <div
                    className="logoutandIcon"
                    onClick={() => {
                      signOutFunc();
                      setIsOpen(false);
                      setToggleSideBar(false);
                    }}
                  >
                    <p className="logoutIcon">
                      {" "}
                      <FaPowerOff size={20} />
                    </p>

                    <p className="logout">Logout</p>
                  </div>
                </div>
              ) : (
                <Tooltip title="Logout" placement="right-start">
                  <p className="logoutIcon" onClick={() => setIsOpen(!isOpen)}>
                    {" "}
                    <FaPowerOff size={20} />
                  </p>
                </Tooltip>
              )}
            </div>

            {userRole !== "owner" && isAuthenticated && (
              <div>
                {isOpen ? (
                  <div>
                    <PromotionRequest
                      button={"Request"}
                      userId={currentUser}
                      isOpen={isOpen}
                    />
                  </div>
                ) : (
                  <div>
                    <PromotionRequest
                      button={"Request"}
                      userId={currentUser}
                      isOpen={isOpen}
                    />{" "}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <main>{children}</main>
    </div>
  );
};

export default SideBar;
