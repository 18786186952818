import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";
import db from "../../Announcment/firebase";
import { useEffect, useState } from "react";
import { MdAutorenew } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import CustomizedAccordions from "../userAccordian/userAccordian";
import AddSquadron from "../userAccordian/addSquadron";
import {RiBuilding2Fill} from 'react-icons/ri'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight:'600px',
  backgroundColor: "#e3e9ee",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

export default function Squadrons() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [sqdData, setSqdData] = useState([]);

  const getSquadron = async () => {
    try {
      setSqdData([]);
      const res = query(collection(db, "squadrons"));
      onSnapshot(res, (snapShot) => {
        snapShot.forEach((obj) => {
          setSqdData((prev) => [
            ...prev,
            {
              buildNumber: obj.data(),
              squadron: obj.id,
            },
          ]);
        });
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  let check = false;
  useEffect(() => {
    if (check === false) {
      getSquadron();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
    }
  }, []);

  const refreshData = () => {
    getSquadron();
    setSqdData([]);
 }

 

  return (
    <div>
      <div onClick={handleOpen} className="sqdBtn">
        <RiBuilding2Fill size={20} color="white" className="icon"/>
        Squadron
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="sqdList">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Squadron List
          </Typography>
          <div>
            {sqdData &&
              sqdData.map((sqd) => {
                return (
                  <div>
                    <CustomizedAccordions
                      squadron={sqd.squadron}
                      buildNumber={sqd.buildNumber}
                      getSquadron={getSquadron}
                      refreshData={refreshData}
                    />
                    
                  </div>
                );
              })}
          </div>
          <div>
            <AddSquadron refreshData={refreshData} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
