import React, { useState } from "react";
import "./MobileNavBar.css";
import { Divide as Hamburger } from "hamburger-react";
import { useAdminContext } from "../../pages/Context/contextStore";
import Logo from "../../assets/logo.jpg";

function MobileNavBar() {
  //   const [toggleSideBar, setToggleSideBar] = useState(false);
  const { toggleSideBar, setToggleSideBar } = useAdminContext();
  return (
    <div className="mainContainerNavBAr">
      <div className={toggleSideBar ? "TextandToggleButton1" : "TextandToggleButton"}>
        <div className={toggleSideBar? "navBarLogoSection1":"navBarLogoSection"}>
          <img src={Logo} alt="logoImage" className="navBarLogo" />
          <p className="NavBarText">Squadron Ally</p>
        </div>

        <div>
                  <div className="toggleNavBar">
            <Hamburger
              toggled={toggleSideBar}
              toggle={setToggleSideBar}
              size={25}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNavBar;
