import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAdminContext } from "../../../pages/Context/contextStore";
import { TextField } from "@mui/material";
import { getAuth } from "firebase/auth";
import db from "../../../pages/Announcment/firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#e3e9ee",
  boxShadow: 24,
  borderRadius: "12px",
  outline: "none",
  p: 4,
};

function UpdateProfile() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [userData, setUserData] = React.useState({
    name: "",
    squadronNo: "",
    buildingNo: "",
  });

  const userId= localStorage.getItem("username");
  const userName = localStorage.getItem("name");
  const auth = getAuth();
  const user = auth.currentUser;

  React.useEffect(() => {
    const userRef = doc(db, "users", userId);
    getDoc(userRef).then((doc) => {
      setUserData({
        name: doc.data()?.name,
        buildingNo: doc.data()?.buildingNumber,
        squadronNo: doc.data()?.squadronNo,
      });
    });
  }, []);

  const updateUser = async () => {
    try {
      const updateRef = doc(db, "users", userId);
      await updateDoc(updateRef, {
        name: userData.name,
        buildingNumber: userData.buildingNo,
        squadronNo: userData.squadronNo,
      });
      console.log("updated");
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleName = (e) => {
    setUserData({
      ...userData,
      name: e.target.value,
    });
  };
  const handleSquadron = (e) => {
    setUserData({
      ...userData,
      squadronNo: e.target.value,
    });
  };
  const handleBuilding = (e) => {
    setUserData({
      ...userData,
      buildingNo: e.target.value,
    });
  };
  

  const btnStyle = {
    fontWeight: " 600",
    fontSize: "large",
    padding: " 0.5rem 0.5rem",
  };

  const updateProfileText = {
    fontSize: "large",
    fontWeight: " 500",
    letterSpacing: "1px",
  };
  const mainInputDiv = {
    marginTop: "10px",
  };
  const inputField = {
    margin: "5px 0",
    width:'90%'
  };
  return (
    <div>
      <p style={btnStyle} onClick={handleOpen}>
        Hey {userName}
      </p>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p style={updateProfileText}>Update Profile</p>
          <div style={mainInputDiv}>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              style={inputField}
              onChange={(e) => handleName(e)}
              value={userData.name}
            />
            <TextField
              id="outlined-basic"
              label="Squadron Number"
              variant="outlined"
              style={inputField}
              onChange={(e) => handleSquadron(e)}
              value={userData.squadronNo}
            />
            <TextField
              id="outlined-basic"
              label="Building Number"
              variant="outlined"
              style={inputField}
              onChange={(e) => handleBuilding(e)}
              value={userData.buildingNo}
            />
           
          </div>
          <button
            className="updateProfileBtn"
            onClick={() => {
              updateUser();
              handleClose();
            }}
          >
            Update
          </button>
        </Box>
      </Modal>
    </div>
  );
}
export default UpdateProfile;
