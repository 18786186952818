import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import { statData } from "./OrderStatsData";
import {
  collection,
  doc,
  updateDoc,
  onSnapshot,
  query,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import db from "../Announcment/firebase";
import "./Order.css";
import { TiTick } from "react-icons/ti";
import { FcProcess } from "react-icons/fc";
import { RiDeleteBin5Fill } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import { Button, TextField } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import AvailableDate from "./availableDate/availableDate";

function Orders() {
  const [orders, setOrders] = useState(statData);
  const [orderDetails, setOrderDetails] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [colorToggle, setColorToggle] = useState("default");
  const [countArray, setCountArray] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [sqdData, setSqdData] = useState([]);
  const [error, setError] = useState(false);
  const [selectedSquadron, setSelectedSquadron] = useState(null);
  const [selectedTab,setSelectedTab]=useState('All')

  const sqdNo = localStorage.getItem("sqdNo");
  const userRole = localStorage.getItem("owner");



  var check = false;
  useEffect(() => {
    setOrderDetails([]);
    setFilteredOrders([]);
    if (check === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
      getOrders();
      getSquadron();
    }
  }, []);

  const refreshOrders = () => {
    setOrderDetails([]);
    setFilteredOrders([]);
    setCountArray([]);
    getOrders();
  };

  // fetching squadron number
  const getSquadron = async () => {
    try {
      setSqdData([]);
      const res = query(collection(db, "squadrons"));
      onSnapshot(res, (snapShot) => {
        snapShot.forEach((obj) => {
          setSqdData((prev) => [
            ...prev,
            {
              squadron: obj.id,
            },
          ]);
        });
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  
  const getOrders = async () => {
    setIsloading(true);
    try {
      const sqdNo = localStorage.getItem("sqdNo");
  
      const que = query(collection(db, "Orders"));
      const querySnapshot = await getDocs(que);
  
      const filteredOrders = [];
      
      querySnapshot.forEach((elem) => {
        const orderData = elem.data();
  
        if (orderData.squadronNo === sqdNo && userRole!=='owner') {
          if (orderData.Status === "Completed") {
            CompletedOrderCount = CompletedOrderCount + 1;
          } if (orderData.Status === "Deleted") {
            RejectedOrderCount = RejectedOrderCount + 1;
          } if (orderData.Status === "Processing") {
            ProcessingOrderCount = ProcessingOrderCount + 1;
          } if (!orderData.Status || orderData.Status === "") {
            NewOrderCount = NewOrderCount + 1;
          }
  
          setCountArray([
            NewOrderCount,
            ProcessingOrderCount,
            CompletedOrderCount,
            RejectedOrderCount,
          ]);
  
          setOrderDetails((prev) => [
            ...prev,
            {
              buildingNumber: orderData.buildingNumber,
              squadronNo: orderData.squadronNo,
              ketoOption: orderData.ketoOption,
              name: orderData.name,
              order: orderData.order,
              status: orderData.Status,
              id: elem.id,
            },
          ]);
  
          if (orderData.Status !== "Deleted") {
            setFilteredOrders((prev) => [
              ...prev,
              {
                buildingNumber: orderData.buildingNumber,
                squadronNo: orderData.squadronNo,
                ketoOption: orderData.ketoOption,
                name: orderData.name,
                order: orderData.order,
                status: orderData.Status,
                id: elem.id,
              },
            ]);
          }
        }
        if (userRole==='owner') {
          if (orderData.Status === "Completed") {
            CompletedOrderCount = CompletedOrderCount + 1;
          }  if (orderData.Status === "Deleted") {
            RejectedOrderCount = RejectedOrderCount + 1;
          }  if (orderData.Status === "Processing") {
            ProcessingOrderCount = ProcessingOrderCount + 1;
          }  if (!orderData.Status || orderData.Status === "") {
            NewOrderCount = NewOrderCount + 1;
          }
  
  
          setCountArray([
            NewOrderCount,
            ProcessingOrderCount,
            CompletedOrderCount,
            RejectedOrderCount,
          ]);
  
          setOrderDetails((prev) => [
            ...prev,
            {
              buildingNumber: orderData.buildingNumber,
              squadronNo: orderData.squadronNo,
              ketoOption: orderData.ketoOption,
              name: orderData.name,
              order: orderData.order,
              status: orderData.Status,
              id: elem.id,
            },
          ]);
  
          if (orderData.Status !== "Deleted") {
            setFilteredOrders((prev) => [
              ...prev,
              {
                buildingNumber: orderData.buildingNumber,
                squadronNo: orderData.squadronNo,
                ketoOption: orderData.ketoOption,
                name: orderData.name,
                order: orderData.order,
                status: orderData.Status,
                id: elem.id,
              },
            ]);
          }
        }
      });
  
      setIsloading(false);
    } catch (error) {
      console.log("Error", error);
    }
  };
  

  // delete Order from fireStore
  const deleteOrder = async (id) => {
    const docRef = doc(db, "Orders", `${id}`);
    try {
      await deleteDoc(docRef);
      refreshOrders();
    } catch (error) {
      console.log("error in deleting order", error);
    }
  };

  // variable to store order counts
  var CompletedOrderCount = 0;
  var RejectedOrderCount = 0;
  var ProcessingOrderCount = 0;
  var NewOrderCount = 0;
  var TotalOrderCount = 0;

  // mapping order details
  let orderData = filteredOrders?.map((elem, i) => {
    return (
      <tr key={i} onClick={() => {}}>
        <td className="id">{i === 0 ? i + 1 : i + 1}</td>
        <td className="name">{elem.name}</td>
        <td className="address">{elem.squadronNo}</td>
        <td className="address">{elem.buildingNumber}</td>
        <td className="address">{elem.order.substring(2)}</td>
        <td className="price">{elem.order.split(" ")[0]}</td>
        <td className="keto">{`${elem.ketoOption}`}</td>
        {elem.status ? (
          <td className="statusOrder">{elem.status}</td>
        ) : (
          <td className="statusOrder">New</td>
        )}
        <td className="actions">
          <span className="Actionicon">
            <Tooltip title="Completed" placement="top-start">
              <Button>
                <TiTick
                  size={30}
                  className="tick"
                  onClick={() => {
                    sendStatus(elem.id, "Completed");
                    refreshOrders();
                  }}
                />
              </Button>
            </Tooltip>
            <Tooltip title="Processing" placement="top-start">
              <Button>
                <FcProcess
                  color="black"
                  size={30}
                  className="tick"
                  onClick={() => {
                    sendStatus(elem.id, "Processing");
                    refreshOrders();
                  }}
                />
              </Button>
            </Tooltip>
            <Tooltip title="Deleted" placement="top-start">
              <Button>
                <RiDeleteBin5Fill
                  size={30}
                  className="tick"
                  onClick={() => {
                    // sendStatus(elem.id, "Deleted");
                    deleteOrder(elem.id);
                    refreshOrders();
                  }}
                />
              </Button>
            </Tooltip>
          </span>
        </td>
      </tr>
    );
  });

  // sending status to database
  const sendStatus = async (updateOrderId, statusTobeUpdated) => {
    try {
      const updateRef = doc(db, "Orders", updateOrderId);
      await updateDoc(updateRef, {
        Status: statusTobeUpdated,
      });
      console.log("Update request sent");
    } catch (error) {
      console.log("Error", error);
    }
  };

  // Filter functions by status value
  const filterStatusByProcessing = () => {
    const processing = orderDetails.filter((order) => {
      return order.status === "Processing";
    });
    setFilteredOrders(processing);
    setColorToggle("processing");
    setSelectedTab("Processing")
  };

  const filterStatusByCompleted = () => {
    const processing = orderDetails.filter((order) => {
      return order.status === "Completed";
    });
    setFilteredOrders(processing);
    setColorToggle("completed");
    setSelectedTab("Completed")
  };

  const filterStatusByNew = () => {
    const processing = orderDetails.filter((order) => {
      return order.status === undefined;
    });
    setFilteredOrders(processing);
    setColorToggle("new");
    setSelectedTab('New')
  };

  const filterAllOrders = () => {
    const orderAllFilter = orderDetails?.filter((order) => {
      return order.status !== "Deleted";
    });
    setFilteredOrders(orderAllFilter);
    setColorToggle("default");
    setSelectedTab("All")
  };

  // Search Box filter function
  const handleSearchBox = (e) => {
    const res = orderDetails.filter((order) => {
      return (
        order.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        order.order.toLowerCase().includes(e.target.value.toLowerCase()) ||
        order?.ketoOption
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        order?.status?.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setFilteredOrders(res);
  };

  // order stats filter function on ViewDetails
  const filterInOrderStats = (status) => {
    // console.log(status);
    if (status === 0) {
      filterStatusByNew();
    } else if (status === 1) {
      filterStatusByProcessing();
    } else {
      filterStatusByCompleted();
    }
  };

  // filterSquadron
  const handleFilter = (squadron) => {
    setSelectedSquadron(squadron)
    const filterOrder = orderDetails.filter((order) => {
      return order.squadronNo === squadron;
    });
    setFilteredOrders(filterOrder);
    if (filterOrder.length > 0) {
      setError(" ");
      setIsloading(false);
    } else {
      setError("Order Not Available");
      setIsloading(true);
    }
  };

  return (
    <div>
      <div className="mainContainer">
        {/* order stats  */}
        <Grid container>
          <div className="cardMain">
            {orders.map((card, i) => {
              return (
                <Grid item xs={12} sm={8} md={4} lg={3.5} key={i}>
                  <div className="cards" key={i}>
                    <Typography variant="h3" mb={1}>
                      {card.Name}
                    </Typography>
                    <div className="numberAndArrow">
                      <p>{countArray[i]}</p>
                    </div>
                    <h6
                      className="ViewMore"
                      onClick={() => {
                        filterInOrderStats(i);
                      }}
                    >
                      View Details
                    </h6>
                  </div>
                </Grid>
              );
            })}
          </div>
        </Grid>

        {/* All details of order */}
        <div className="orderList">
          <p className="orderTitle">Order Lists</p>
          {userRole === "owner" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#e3e9ee",
                width: "100%",
                margin: "10px 0",
                padding: "10px 10px",
              }}
            >
              <div className="orderFilterBtn">
                {sqdData &&
                  sqdData.map((item) => {
                    const isSelected = item.squadron === selectedSquadron;
                    return (
                      <>
                        <button
                          className={isSelected ? 'selected' : 'notSelected'}
                          onClick={() => {
                            handleFilter(item.squadron);
                          }}
                        >
                          {item.squadron}
                        </button>
                      </>
                    );
                  })}
              </div>
              <div className="refreshOrder">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#013191" }}
                  onClick={refreshOrders}
                  mb={1}
                >
                  Refresh Orders
                </Button>
              </div>
            </div>
          )}

          <div className="searchBoxandTabs">
            <div className="tabsMain">
              <div>
                <p
                  // className="tabBtn"
                  className={`tabBtn ${selectedTab === 'All' ? 'selectedF' : 'notSelectedF'}`}
                  onClick={() => {
                    filterAllOrders();
                  }}
                >
                  All
                </p>
              </div>
              <div>
                <p
                  // className="tabBtn"
                  className={`tabBtn ${selectedTab === 'New' ? 'selectedF' : 'notSelectedF'}`}
                  onClick={() => filterStatusByNew()}>
                  New
                </p>
              </div>
              <div>
                <p
                  // className="tabBtn"
                  className={`tabBtn ${selectedTab === 'Processing' ? 'selectedF' : 'notSelectedF'}`}
                  onClick={() => filterStatusByProcessing()}
                >
                  Processing
                </p>
              </div>
              <div>
                <p
                  // className="tabBtn"
                  className={`tabBtn ${selectedTab === 'Completed' ? 'selectedF' : 'notSelectedF'}`}
                  onClick={() => filterStatusByCompleted()}>
                  Completed
                </p>
              </div>
              {/* <div>
                <p className="tabBtn" onClick={() => filterStatusByRejected()}>
                  Deleted
                </p>
              </div> */}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AvailableDate />
              <div
                className="searchBox"
                style={{
                  marginBottom: "0.5rem",
                  borderRadius: "12px",
                  lineHeight: "1em",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Search Order"
                  variant="outlined"
                  onChange={(e) => handleSearchBox(e)}
                  style={{
                    width: "19rem",
                    borderRadius: "12px",
                    lineHeight: "1em",
                    lineHeight: "1em",
                  }}
                  className="orderSearchBox"
                />
              </div>
            </div>
          </div>
          <h3 style={{ color: "red", textAlign: "center" }}>{error}</h3>
          <div>
            {isLoading ? (
              <div className="loader">
                <RotatingLines
                  strokeColor="blue"
                  strokeWidth="2"
                  animationDuration="0.75"
                  width="50"
                  visible={true}
                />
              </div>
            ) : (
              <div className="orderDetails">
                <table id="orderTable" className="default">
                  <thead>
                    <tr>
                      <th>ID </th>
                      <th>Name</th>
                      <th>SquadronNo</th>
                      <th>BuildingNo</th>
                      <th>Order</th>
                      <th>Price</th>
                      <th>Keto</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{orderData}</tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="Footer">
        <Footer />{" "}
      </div>
    </div>
  );
}

export default Orders;
