export const CommentsstatData = [
    {
      Name: "New Comments",
      Count: "45",
      Path: "/",
    },
    {
      Name: "Comments In Process",
      Count: "45",
      Path: "/",
  },
  {
    Name: "Completed Comments",
    Count: "45",
    Path: "/",
  },
  {
    Name: "Deleted Comments",
    Count: "45",
    Path: "/",
  },
  ];