// import * as firebase from 'firebase'
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable  } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDm45d01erXPet4XqKbqETYSTcFMYFZBg8",
  authDomain: "scp-302a2.firebaseapp.com",
  projectId: "scp-302a2",
  storageBucket: "scp-302a2.appspot.com",
  messagingSenderId: "1080674504374",
  appId: "1:1080674504374:web:f0a39f4f2119ece4546017",
  measurementId: "G-LHCMNQ0HZ0",
};

 const firebase = initializeApp(firebaseConfig)
var db = getFirestore(firebase);
// export let Authh = getAuth(firebase)
const functions=getFunctions(firebase)
export const deleteUserFunction =httpsCallable(functions, 'deleteUser');
export default db;
