import * as React from "react";
import Popover from "@mui/material/Popover";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";
import db from "../../Announcment/firebase";

export default function AddBuild({ sqdId, buildObject,refreshData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [build, setBuild] = React.useState(null);

  const handleInput = (e) => {
    setBuild(e.target.value);
  };

  const handleBuild = async () => {
    try {
      const docRef = doc(db, "squadrons", sqdId);
      await updateDoc(docRef, {
        [Object.keys(buildObject).length + 1]: build,
      });
      handleClose();
      refreshData();
    } catch (error) {
      console.log("error", error);
    }
  };


  return (
    <div>
      <button aria-describedby={id} className="sqdBtn" onClick={handleClick}>
        New Building
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="BuildMain">
          <p style={{ fontSize: "17px", fontWeight: "bold", padding: "5px" }}>
            Add New Building
          </p>
          <input
            type="text"
            placeholder="New Build"
            onChange={(e) => handleInput(e)}
            className="buildInput"
          />
          <button className="addBuildBtn" onClick={() => handleBuild()}>
            Add
          </button>
        </div>
      </Popover>
    </div>
  );
}
