import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";
import db from "./firebase";
import "./Announcment.css";
import Modal from "./Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import AddAnnouncement from "./addAnnouncment/AnnouncementForm";

function Announcment() {
  const [fetchAnnouncement, setFetchAnnouncement] = useState([]);
  const [toggelModal, setToggelModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  var check = false;
  useEffect(() => {
    setFetchAnnouncement([]);
    localStorage.getItem("sqdNo");
    localStorage.getItem("owner");
    if (check === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
      fetchAnnouncment();
    }
  }, []);

  const fetchAnnouncment = () => {
    setIsloading(true);
    try {
      setFetchAnnouncement([]);
      const fetchRes = query(collection(db, "Announcements"));
      const res = onSnapshot(fetchRes, (snapShot) => {
        snapShot.forEach((doc) => {
          const announcement = doc.data();
          // Filter announcements based on squadronNo
          setFetchAnnouncement((prev) => [
            ...prev,
            {
              text: announcement.text,
              squadronNo: announcement.squadronNo,
              date: announcement.date,
              id: doc.id,
            },
          ]);
        });
      });
      setIsloading(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  // reload function to reload page
  function refreshData() {
    setFetchAnnouncement([]);
    fetchAnnouncment();
  }

  // Delete Announcemnet
  const deleteAnnouncement = async (id) => {
    try {
      const deleteId = doc(db, "Announcements", id);
      await deleteDoc(deleteId);
      deleteToast();
      refreshData();
    } catch (error) {
      console.log("Error", error);
    }
  };

  //  Toast Message Function
  const deleteToast = () => {
    toast("Announcement Deleted 🗑", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const sendToast = () => {
    toast("Announcement Added 📜", {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div className="announcements">
      {/* <h2 className="announcementTitle">Announcements</h2> */}
      <AddAnnouncement refreshData={refreshData} sendToast={sendToast} />

      {/* Add Announcement Ended */}

      <div className="scrollView">
        {isLoading ? (
          <div className="loader">
            <RotatingLines
              strokeColor="white"
              strokeWidth="2"
              animationDuration="0.75"
              width="50"
              visible={true}
            />
          </div>
        ) : (
          <div>
            {fetchAnnouncement?.map((announcement, i) => {
              return (
                <div className="announcement" key={i}>
                  {announcement.squadronNo === "all" ||
                  announcement.squadronNo === undefined ? (
                    <p style={{ fontWeight: "600" }}>For All Squadron</p>
                  ) : (
                    <p style={{ fontWeight: "600" }}>
                      For Squadron: {announcement.squadronNo}
                    </p>
                  )}

                  <p>{announcement.text}</p>
                  <p>{announcement.date}</p>

                  <div className="buttonsSection">
                    <div className="button">
                      <Modal
                        toggle={toggelModal}
                        buttonName={"Update"}
                        docId={announcement.id}
                        docText={announcement.text}
                        docSquadron={announcement.squadronNo}
                        refreshData={refreshData}
                      />
                    </div>
                    <div className="button">
                      <button
                        onClick={() => {
                          deleteAnnouncement(announcement.id);
                        }}
                        className="deleteBtn"
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default Announcment;
