import { BrowserRouter } from "react-router-dom";
import "./App.css";
import SideBar from "./components/SideBar/SideBar";
import RoutesComponent from "./Routes/Routes";
import ContextProvider from "./pages/Context/contextStore";
import MobileNavBar from "./components/MobileNavBar/MobileNavBar";
import { baseUrlHosting } from "./components/Config/url";
const App = () => {
  return (
    <ContextProvider>
      <BrowserRouter>
        {/* <BrowserRouter basename={baseUrlHosting}> */}
        <SideBar>
          <MobileNavBar />
          <RoutesComponent />
        </SideBar>
      </BrowserRouter>
    </ContextProvider>
  );
};

export default App;
