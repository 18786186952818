import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
// import "./Modal.css";
import { doc, updateDoc,collection } from "firebase/firestore";
import db from "../Announcment/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './FaqModal.css'
import { useAdminContext } from "../Context/contextStore";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
  borderRadius: '12px',
  backgroundColor:'#e3e9ee'
};

function FaqModal({ buttonName, question, answer, link, docId, docName ,refreshData}) {
  const {categoryId}=useAdminContext()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({
    question: question,
    answer: answer,
    link: link,
  });
  // update Announcement
  const updateFaq = async () => {
    const result =
      data.question.toUpperCase() === question.toUpperCase() &&
      data.answer.toUpperCase() === answer.toUpperCase() &&
      data.link.toUpperCase() === link.toUpperCase();
    if (!result) {
      try {
        // Access the main document using docName and docId
      const mainDocRef = doc(db, 'FAQs', categoryId);

        const nestedCollectionRef = collection(mainDocRef, "questions");

      // Access the specific nested document you want to update
      const nestedDocRef = doc(nestedCollectionRef, docId); // Replace nestedDocId with the actual ID of the nested document

      // Update the nested document fields
      await updateDoc(nestedDocRef, {
        question: data.question,
        answer: data.answer,
        link: data.link,
      });
        updateToast();
        refreshData();
        // reloadPage();
      } catch (error) {
        console.log("Error", error);
      }
    } else {
      alert('Please make some change to update')
    }
  };

  //   reload page
  function reloadPage() {
    window.location.reload(true);
  }

  //   update toast message function
  const updateToast = () => {
    toast("updated 📑", {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  function handleQuestion(e) {
    setData({
      ...data,
      question: e.target.value,
    });
  }
  function handleAnswer(e) {
    setData({
      ...data,
      answer: e.target.value,
    });
  }
  function handleLink(e) {
    setData({
      ...data,
      link: e.target.value,
    });
  }

  return (
    <div className="mainFaqModal">
      <Button onClick={handleOpen} className="updateFaqQA" >
        {buttonName}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h4" mb={1} style={{ color: "rgb(101, 116, 182)" }}>
            UPDATE YOUR FAQ
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={1}>
            Question
          </Typography>
          <TextField
            id="outlined-basic"
            label="Update Question"
            variant="outlined"
            value={data.question}
            onChange={(e) => handleQuestion(e)}
            style={{ width: '19rem', borderRadius:'12px',fontWeight:'600',lineHeight:'1em',marginBottom:'0.5rem'}}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={1}>
            Answer
          </Typography>
          <TextField
            id="outlined-basic"
            label="Update Answer"
            variant="outlined"
            value={data.answer}
            onChange={(e) => handleAnswer(e)}
            style={{ width: '19rem', borderRadius: '12px',lineHeight:'1em',marginBottom:'0.5rem'}}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={1}>
            Link
          </Typography>
          <TextField
            className="faqModalInput"
            id="outlined-basic"
            label="Update Link"
            variant="outlined"
            value={data.link}
            onChange={(e) => handleLink(e)}
            style={{ width: '19rem', borderRadius: '12px',lineHeight:'1em',marginBottom:'0.5rem'}}
          />
          <p></p>
           <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              updateFaq();
              setOpen(false);
            }}
            className="updateFaq"
            style={{marginLeft:'1.5rem',backgroundColor:'#013191'}}
          >
            Update
          </Button>
          <ToastContainer pauseOnHover />
        </Box>
      </Modal>
    </div>
  );
}

export default FaqModal;
