import {
  Button,
  CssBaseline,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import "./Login.css";
import CompanyLogo from "../../assets/logo.jpg";
import { BsArrowRightCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import db from "../Announcment/firebase";
import { useAdminContext } from "../Context/contextStore";
import Footer from "../../components/Footer/Footer";
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { BiShow, BiHide } from "react-icons/bi";
import CryptoJS from "crypto-js";

import { ToastContainer, toast } from "react-toastify";

function Login() {
  const navigation = useNavigate();
  const { signIn, verifyOwnerFunc } = useAdminContext();
  const [viewPassword, setViewPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // this function is for password type changing
  const toggle = (value) => {
    setViewPassword(!viewPassword);
  };

  // navigate to signup function
  const goToSignUp = () => {
    navigation("/signup", { replace: true });
  };
  // navigate to dashboard function
  const goToDashboard = () => {
    navigation("/dashboard", { replace: true });
  };

  // handling Inputs
  const handleUsername = (e) => {
    const newUsername = e.target.value.trim().toLowerCase();
  
    setCredentials({
      ...credentials,
      username: newUsername,
    });
  };
  
  const handlePassword = (e) => {
    const password=e.target.value
    setCredentials({
      ...credentials,
      password: password,
    });
  };

  // password validitons function
  const passwordValidation = () => {
    let isValid = true;
    if (credentials.password !== "") {
      if (credentials.password.length < 8) {
        isValid = false;
        setPasswordError("password is too short");
      } else {
        setPasswordError(" ");
      }
    } else {
      isValid = false;
    }
    if (credentials.password === "") {
      isValid = false;
      setPasswordError("Please insert Password");
    }

    return isValid;
  };

  // userName Validation function
  const usernameValidation = () => {
    let result = true; // Assuming the username is initially valid

    if (credentials.username.trim() === "") {
      result = false; // Empty username is not valid
      setUsernameError("Username cannot be empty");
    } else {
      const regex = /^[a-zA-Z0-9_-]{3,16}$/; // Example regex for alphanumeric usernames with length 3-16
      result = regex.test(credentials.username);

      if (!result) {
        setUsernameError("Invalid username");
      } else {
        setUsernameError(""); // Clear the error message if the username is valid
      }
    }

    return result;
  };

  // toast message
  const inValidUser = () => {
    toast("Invalid User!!!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // signIn user from databse

  const SignInFunc = async () => {
    if (usernameValidation() && passwordValidation()) {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const userDoc = querySnapshot.docs.find(
          (doc) => doc.data().username === credentials.username
        );

        if (userDoc) {
          const userData = userDoc.data();

          const hashedPassword = CryptoJS.SHA256(credentials.password).toString(
            CryptoJS.enc.Hex
          );

          if (userData.password === hashedPassword) {
            signIn("avX2#xpLe2@s9");
            // Fetch additional user data from Firestore based on username
            const additionalUserDataDoc = await getDoc(
              doc(db, "users", credentials.username)
            );
            const additionalUserData = additionalUserDataDoc.data();
            // Store additional user data in localStorage
            localStorage.setItem("owner", additionalUserData.role);
            localStorage.setItem("sqdNo", additionalUserData.squadronNo);
            localStorage.setItem("name", additionalUserData.name);
            localStorage.setItem("username", additionalUserData.username);
            verifyOwner();
            goToDashboard();
          } else {
            // Password doesn't match
            inValidUser();
          }
        } else {
          // User not found
          inValidUser();
        }
      } catch (error) {
        console.log("Error:", error.message);
      }
    }
  };

  // forget password function
  const auth = getAuth();
  // const forgetPassword = async () => {
  //   if (emailValidation() === true) {
  //     await sendPasswordResetEmail(auth, credentials.email);
  //     setCredentials(" ");
  //   } else {
  //     setUsernameError("Please Enter valid User Name");
  //   }
  // };
  const verifyOwner = async () => {
    const userType = localStorage.getItem("owner");
    const userName = localStorage.getItem("name");
    verifyOwnerFunc(userType, userName);
  };

  return (
    <div>
      <div className="mainDivLogin">
        <div className="insideMain">
          <Grid container className="grid">
            <CssBaseline />
            <Grid item lg={4} md={6} sm={6}>
              <div className="imgMain">
                <div className="imgDiv">
                  <img src={CompanyLogo} alt="companyLogo" className="img" />
                  <h2 className="welcome">Welcome Admin</h2>
                  <div className="textandIcon">
                    <p className="pleaseLogin">Please signIn from here</p>
                    <label for="outlined-basic">
                      <BsArrowRightCircle size={20} className="loginIcon" />
                    </label>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={11.5}>
              <div className="formDiv">
                <div className="mobileViewLogoSection">
                  <div className="mobileImgDiv">
                    <img
                      src={CompanyLogo}
                      alt="companyLogo"
                      className="mobileViewLogo"
                    />
                  </div>
                  <div className="mobileViewText">
                    <p>Welcome Admin</p>
                  </div>
                </div>
                <h2 className="loginForm" id="123" style={{ color: "#013191" }}>
                  SignIn Form
                </h2>
                <p className="credentials">Enter the following credentials :</p>
                <p className="error">{usernameError}</p>
                <div className="inputs">
                  <TextField
                    id="outlined-basica"
                    label="Enter User Name"
                    variant="outlined"
                    required
                    type="text"
                    value={credentials.username}
                    onChange={(e) => {
                      handleUsername(e);
                    }}
                    sx={{
                      marginTop: "0.5rem",
                      width: "19rem",
                      lineHeight: "1em",
                      borderRadius: "12px!important",
                    }}
                    className="inputFeildLogin"
                  />
                  <p className="error">{passwordError}</p>
                  <div>
                    <TextField
                      type={viewPassword ? "text" : "password"}
                      id="outlined-basicb"
                      label="Enter password"
                      variant="outlined"
                      required
                      value={credentials.password}
                      onChange={(e) => {
                        handlePassword(e);
                      }}
                      sx={{
                        marginTop: "0.5rem",
                        width: "19rem",
                        lineHeight: "1em",
                        borderRadius: "12px!important",
                      }}
                      className="inputFeildLogin"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span style={{ background: "transparent" }}>
                              {viewPassword ? (
                                <BiHide
                                  onClick={() => {
                                    toggle(false);
                                  }}
                                  size={20}
                                />
                              ) : (
                                <BiShow
                                  onClick={() => {
                                    toggle(true);
                                  }}
                                  size={20}
                                />
                              )}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <p
                    className="forgetPassword"
                    onClick={() => {
                      // forgetPassword();
                    }}
                  >
                    Forgot Password!
                  </p>
                </div>
                <div className="LoginBtnDiv">
                  <Button
                    className="LoginBtn"
                    onClick={() => {
                      SignInFunc();
                    }}
                    style={{ backgroundColor: "#013191" }}
                  >
                    SignIn
                  </Button>
                </div>
                {/* <div className="notRegistered">
                <p>Not Registered! </p>
                <p
                  className="signUpLogin"
                  onClick={() => {
                    goToSignUp();
                  }}
                >
                  {" "}
                  SignUp
                  </p>
                  
              </div> */}
              </div>
            </Grid>
          </Grid>
          <ToastContainer pauseOnHover />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
