import * as React from "react";
import Popover from "@mui/material/Popover";
import { getDocs, query, collection, updateDoc, doc } from "firebase/firestore";
import db from "../../Announcment/firebase";
import Badge from "@mui/material/Badge";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { useEffect } from "react";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";


const roleStyle = {
  fontSize: "17px",
  fontWeight: "500",
  marginBottom: "5px",
};
const popStyle = {
  padding: "5px 10px",
  width: "250px",
  height: "250px",
  borderRadius: "8px",
  overflow: "auto",
  "& ::-webkit-scrollbar": {
    display: "none",
  },
};
const notiMain = {
  backgroundColor: "rgb(240, 240, 241)",
  padding: "5px 10px",
  borderRadius: "8px",
  margin: "5px 0",
};

const titleStyle = {
  fontWeight: "500",
};


const Notification = () => {
  const [anchorEl, setAnchorEl] =useState(null);
  const [requests, setRequests] =useState([]);
  const [unReadNoti, setUnReadNoti] =useState()
  const [isLoading, setIsloading] =useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    updateStatsus();
    
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let check = false;
  useEffect(() => {
    setRequests([]);
    if (check === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
      getRequests();
    }
  }, []);
  var unReadNotification = 0;
  // fetching request
  const getRequests = async () => {
    try {
      setIsloading(true);
      const docRef = query(collection(db, "Requests"));
      const snapShot = await getDocs(docRef);
      snapShot.forEach((req) => {

        // checking the count of unread notifications

        if (req.data().status === "unread") {
          unReadNotification = unReadNotification + 1;
        }
        setUnReadNoti(unReadNotification);
        
        setRequests((prev) => [
          ...prev,
          {
            post: req.data().post,
            reqDetail: req.data().reqDetail,
            status: req.data().status,
            type: req.data().type,
            mail: req.id,
          },
        ]);
      });
      setIsloading(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const updateStatsus = async () => {
    try {
      setRequests([]);
      const querySnapshot = await getDocs(collection(db, "Requests"));
      
      // Loop through each document in the collection
      querySnapshot.forEach((docSnapshot) => {
        const docRef = doc(db, "Requests", docSnapshot.id);
        
        // Update the status field of each document to "read"
        updateDoc(docRef, {
          status: "read"
        });
      });
      setUnReadNoti(0);
      setRequests([]);
      getRequests();
      console.log("All documents updated successfully.");
    } catch (error) {
      console.log("Error updating documents:", error);
    }
  }
  return (
    <div>
      <button
        onClick={handleClick}
        style={{ border: "none", cursor: "pointer" }}
      >
        <Badge badgeContent={unReadNoti} color="primary">
          <CircleNotificationsIcon
            style={{ fontSize: "37px", color: "black" }}
            color="action"
          />
        </Badge>
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{
          left: "40px",
          top: "5px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={popStyle}>
          <p style={roleStyle}>Notifications </p>
          {
            isLoading ? (
              <div className="loader">
                <RotatingLines
                  strokeColor="blue"
                  strokeWidth="2"
                  animationDuration="0.75"
                  width="30"
                  visible={true}
                />
              </div>
            ) : (<div>
                {requests &&
              requests.map((req) => {
                return (
                  <div style={notiMain}>
                    <p>
                      <spam style={titleStyle}>From:</spam> {req.mail}
                    </p>
                    <p>
                      <spam style={titleStyle}>Type:</spam> {req.type}
                    </p>
                    <p>
                      <spam style={titleStyle}>Request:</spam> for post {req.post}
                    </p>
  
                    <p>
                      <spam style={titleStyle}>Details:</spam> {req.reqDetail}
                    </p>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <p>
                        <spam style={titleStyle}>Status:</spam> {req.status}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>)
          }
        
        </div>
      </Popover>
    </div>
  );
};

export default Notification;
