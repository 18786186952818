import React, { useEffect } from "react";

import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  collection,
  doc,
  setDoc,
  query,
  getDocs,
  deleteDoc,
  getDoc,
  where,
  updateDoc,
} from "firebase/firestore";
import db from "../Announcment/firebase";

const submitBtn = {
  border: "none",
  borderRadius: "8px",
  backgroundColor: "blue",
  color: "white",
  padding: "7px 10px",
  fontWeight: "500",
  fontSize: "18px",
  cursor: "pointer",
};

export default function CalendarDates({ handleClose, squadron }) {
  const [selectedDates, setSelectedDates] = React.useState([]);
  let check = false;
  useEffect(() => {
    if (check === false) {
      getAvailableDates();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
    }
  }, []);

  // const getAvailableDates = async () => {
  //   setSelectedDates([]); // Clear the selectedDates state
  //   const sqdNo = localStorage.getItem("sqdNo");
  //   try {
  //     const squadronDocRef = doc(db, "availableDates", squadron?squadron:sqdNo);
  //     const squadronDocSnapshot = await getDoc(squadronDocRef);

  //     if (squadronDocSnapshot.exists()) {
  //       const squadronData = squadronDocSnapshot.data();

  //       // Extract the available dates and update selectedDates state
  //       const availableDates = Object.values(squadronData);
  //       setSelectedDates(availableDates);

  //       console.log("Dates fetched from Firestore");

  //       // Add the provided code snippet here to update selectedDates
  //       squadronDocSnapshot.forEach((obj) => {
  //         setSelectedDates((prev) => [...prev, obj.data().availableDate]);
  //       });
  //     } else {
  //       console.log("No dates found in Firestore for the squadron");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching dates:", error);
  //   }
  // };

  // const handleDayClick = async (date) => {
  //   const sqdNo = localStorage.getItem("sqdNo");
  //   const selectedDate = formatDate(date);

  //   try {
  //     const squadronDocRef = doc(db, "availableDates", squadron?squadron:sqdNo);
  //     const squadronDocSnapshot = await getDoc(squadronDocRef);

  //     if (squadronDocSnapshot.exists()) {
  //       const squadronData = squadronDocSnapshot.data();

  //       if (squadronData[selectedDate]) {
  //         // Date already selected, remove it
  //         delete squadronData[selectedDate];
  //         await setDoc(squadronDocRef, squadronData);
  //         console.log("Date removed from Firestore");
  //       } else {
  //         // Date not selected, add it
  //         squadronData[selectedDate] = selectedDate;
  //         await setDoc(squadronDocRef, squadronData);
  //         console.log("Date added to Firestore");
  //       }
  //     } else {
  //       // Create a new document with the selected date
  //       const newData = { [selectedDate]: selectedDate };
  //       await setDoc(squadronDocRef, newData);
  //       console.log("Date added to Firestore");
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // };

  const getAvailableDates = async () => {
    const sqdNo = localStorage.getItem("sqdNo");
    try {
      const squadronDocRef = doc(
        db,
        "availableDates",
        squadron ? squadron : sqdNo
      );
      const squadronDocSnapshot = await getDoc(squadronDocRef);

      if (squadronDocSnapshot.exists()) {
        const squadronData = squadronDocSnapshot.data();
        const availableDates = Object.values(squadronData);
        setSelectedDates(availableDates);
        console.log("Dates fetched from Firestore");
      } else {
        setSelectedDates([]);
        console.log("No dates found in Firestore for the squadron");
      }
    } catch (error) {
      console.log("Error fetching dates:", error);
    }
  };

  const handleDayClick = async (date) => {
    const sqdNo = localStorage.getItem("sqdNo");
    const selectedDate = formatDate(date);

    // Update the local state immediately
    setSelectedDates((prevDates) => {
      const isDateSelected = prevDates.includes(selectedDate);
      if (isDateSelected) {
        // Date already selected, remove it locally
        return prevDates.filter((date) => date !== selectedDate);
      } else {
        // Date not selected, add it locally
        return [...prevDates, selectedDate];
      }
    });

    // Update Firestore document asynchronously
    try {
      const squadronDocRef = doc(
        db,
        "availableDates",
        squadron ? squadron : sqdNo
      );
      const squadronDocSnapshot = await getDoc(squadronDocRef);

      if (squadronDocSnapshot.exists()) {
        const squadronData = squadronDocSnapshot.data();

        if (squadronData[selectedDate]) {
          // Date already selected, remove it
          delete squadronData[selectedDate];
          console.log("Date removed from Firestore");
        } else {
          // Date not selected, add it
          squadronData[selectedDate] = selectedDate;
          console.log("Date added to Firestore");
        }

        // Update Firestore document
        await setDoc(squadronDocRef, squadronData);
      } else {
        // Create a new document with the selected date
        const newData = { [selectedDate]: selectedDate };
        await setDoc(squadronDocRef, newData);
        console.log("Date added to Firestore");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleDate = () => {
    handleClose();
  };

  const formatDate = (date) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <DayPicker
        selected={selectedDates.map((dateString) => new Date(dateString))}
        onDayClick={handleDayClick}
        modifiers={{
          selected: selectedDates.map((dateString) => new Date(dateString)),
        }}
      />

      <button
        style={submitBtn}
        onClick={() => {
          handleDate();
        }}
      >
        Submit
      </button>
    </div>
  );
}
