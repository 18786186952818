import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import "./FeedBack.css";
import { CommentsstatData } from "../Stats/FeedbackStats";
import { Grid, Typography } from "@mui/material";
import {
  getDocs,
  query,
  collection,
  doc,
  updateDoc,
  deleteDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import db from "../Announcment/firebase";
import Tooltip from "@mui/material/Tooltip";
import { Button, TextField } from "@mui/material";
import { TiTick } from "react-icons/ti";
import { FcProcess } from "react-icons/fc";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { RotatingLines } from "react-loader-spinner";
import { useAdminContext } from "../Context/contextStore";
import { AiFillDelete } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";

function Feedbacks() {
  const { verifyOwner, verifyOwnerFunc } = useAdminContext();
  const [feedBackStats, setFeedbackStats] = useState(CommentsstatData);
  const [feedBackData, setFeeedBackData] = useState([]);
  const [filteredFeedBack, setFilteredFeedBack] = useState([]);
  const [countArray, setCountArray] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  var check = false;
  const checkUser = localStorage.getItem("owner");

  useEffect(() => {
    setFeeedBackData([]);
    setFilteredFeedBack([]);
    setSelectedTab("All");
    if (check === false) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      check = true;
      getFeedBacks();
    }
  }, []);

  // delete comment permanent
  const DeletePermanent = async (id) => {
    try {
      const docRef = doc(db, "Suggestions", id);

      // Delete the document
      await deleteDoc(docRef);
      RefreshFeedback();
      setSelectedTab("All");
      console.log("feedback deleted");
    } catch (error) {
      console.log("error in delete comments", error);
    }
  };

  // Fetch FeedBacks from DataBase
  const getFeedBacks = async () => {
    try {
      setIsloading(true);
      const sqdNo = localStorage.getItem("sqdNo");
      const userRole = localStorage.getItem("owner");
      const feedbacks = query(collection(db, "Suggestions"));
      const snapShot = await getDocs(feedbacks);
      snapShot.forEach((elem) => {
        const suggestions = elem.data();
        if (suggestions.squadronNo === sqdNo && userRole !== "owner"&& userRole !== "TcHeadAdmin") {
          if (elem) {
            TotalFeedabackCount = TotalFeedabackCount + 1;
          }
          if (elem.data().Status === "Completed") {
            CompletedFeedabackCount = CompletedFeedabackCount + 1;
          }
          if (elem.data().Status === "Deleted") {
            RejectedFeedabackCount = RejectedFeedabackCount + 1;
          }
          if (elem.data().Status === "Processing") {
            ProcessingFeedabackCount = ProcessingFeedabackCount + 1;
          }
          if (!elem.data().Status || !elem.data().Status === "") {
            NewFeedabackCount = NewFeedabackCount + 1;
          }

          setCountArray([
            NewFeedabackCount,
            ProcessingFeedabackCount,
            CompletedFeedabackCount,
            RejectedFeedabackCount,
          ]);

          setFeeedBackData((prev) => [
            ...prev,
            {
              anonymous: elem.data().anonymous,
              building: elem.data().building,
              followUp: elem.data().followUp,
              fullName: elem.data().name,
              date: elem.data().date,
              request: elem.data().request,
              suggestion: elem.data().suggestion,
              status: elem.data().Status,
              id: elem.id,
            },
          ]);
          if (elem.data().Status !== "Deleted") {
            setFilteredFeedBack((prev) => [
              ...prev,
              {
                anonymous: elem.data().anonymous,
                building: elem.data().building,
                followUp: elem.data().followUp,
                date: elem.data().date,
                fullName: elem.data().name,
                request: elem.data().request,
                suggestion: elem.data().suggestion,
                status: elem.data().Status,
                id: elem.id,
              },
            ]);
          }
        }
        if (userRole === "owner" || userRole==="TcHeadAdmin") {
          if (elem) {
            TotalFeedabackCount = TotalFeedabackCount + 1;
          }
          if (elem.data().Status === "Completed") {
            CompletedFeedabackCount = CompletedFeedabackCount + 1;
          }
          if (elem.data().Status === "Deleted") {
            RejectedFeedabackCount = RejectedFeedabackCount + 1;
          }
          if (elem.data().Status === "Processing") {
            ProcessingFeedabackCount = ProcessingFeedabackCount + 1;
          }
          if (!elem.data().Status || !elem.data().Status === "") {
            NewFeedabackCount = NewFeedabackCount + 1;
          }
          setCountArray([
            NewFeedabackCount,
            ProcessingFeedabackCount,
            CompletedFeedabackCount,
            RejectedFeedabackCount,
          ]);

          setFeeedBackData((prev) => [
            ...prev,
            {
              anonymous: elem.data().anonymous,
              building: elem.data().building,
              followUp: elem.data().followUp,
              fullName: elem.data().name,
              date: elem.data().date,
              request: elem.data().request,
              suggestion: elem.data().suggestion,
              status: elem.data().Status,
              id: elem.id,
            },
          ]);
          if (elem.data().Status !== "Deleted") {
            setFilteredFeedBack((prev) => [
              ...prev,
              {
                anonymous: elem.data().anonymous,
                building: elem.data().building,
                followUp: elem.data().followUp,
                date: elem.data().date,
                fullName: elem.data().name,
                request: elem.data().request,
                suggestion: elem.data().suggestion,
                status: elem.data().Status,
                id: elem.id,
              },
            ]);
          }
        }
      });
      setIsloading(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  // variable to store feedbacks counts
  var CompletedFeedabackCount = 0;
  var RejectedFeedabackCount = 0;
  var ProcessingFeedabackCount = 0;
  var NewFeedabackCount = 0;
  var TotalFeedabackCount = 0;

  // Refresh Feedback data
  const RefreshFeedback = () => {
    getFeedBacks();
    setFeeedBackData([]);
    setFilteredFeedBack([]);
    setSelectedTab("All");
  };

  const formatDate = (date) => {
    const jsDate = new Date(date * 1000);
    const formattedDate = jsDate.toLocaleString();
    return formattedDate;
  };

  // Mapping feedbacks data in table
  let feedBacksTableData = filteredFeedBack?.map((elem, i) => {
    return (
      <>
        <tr tr key={i} onClick={() => {}}>
          <td className="id">{i === 0 ? i + 1 : i + 1}</td>
          <td className="name">
            {!verifyOwner && elem.anonymous ? "Anonymous" : elem.fullName}
          </td>
          <td className="request">{elem.request}</td>
          <td className="followUp">{`${elem.followUp}`}</td>
          <td className="anonymous">{`${elem.anonymous}`}</td>
          <td className="anonymous">{formatDate(elem.date.seconds)}</td>
          <td className="building">
            {!verifyOwner && elem.anonymous ? "Anonymous" : elem.building}
          </td>
          <td className="suggestion">{elem.suggestion}</td>
          {elem.status ? (
            <td className="statusRow">{elem.status}</td>
          ) : (
            <td>New</td>
          )}
          <td className="actions">
            <span className="Actionicon">
              <Tooltip title="Completed" placement="top-start">
                <Button>
                  <TiTick
                    size={30}
                    className="tick"
                    onClick={() => {
                      sendStatus(elem.id, "Completed");
                      RefreshFeedback();
                    }}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Processing" placement="top-start">
                <Button>
                  <FcProcess
                    color="black"
                    size={30}
                    className="tick"
                    onClick={() => {
                      sendStatus(elem.id, "Processing");
                      RefreshFeedback();
                    }}
                  />
                </Button>
              </Tooltip>
              {checkUser === "owner" && selectedTab === "Deleted" ? (
                <Tooltip title="Delete Permanent" placement="top-start">
                  <Button>
                    <AiOutlineCloseCircle
                      size={30}
                      color="red"
                      className="tick"
                      onClick={() => {
                        DeletePermanent(elem.id);
                        RefreshFeedback();
                      }}
                    />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Deleted" placement="top-start">
                  <Button>
                    <RiDeleteBin5Fill
                      size={30}
                      className="tick"
                      onClick={() => {
                        sendStatus(elem.id, "Deleted");
                        RefreshFeedback();
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
            </span>
          </td>
        </tr>
      </>
    );
  });

  // sending status to database
  const sendStatus = async (updateFeedbackId, statusTobeUpdated) => {
    try {
      const updateRef = doc(db, "Suggestions", updateFeedbackId);
      await updateDoc(updateRef, {
        Status: statusTobeUpdated,
      });
      setSelectedTab("All");
    } catch (error) {
      console.log("Error", error);
    }
  };

  // All filter functions
  const filterStatusByProcessing = () => {
    const processing = feedBackData.filter((order) => {
      return order.status === "Processing";
    });
    setFilteredFeedBack(processing);
    setSelectedTab("Processing");
  };

  const filterStatusByCompleted = () => {
    const processing = feedBackData.filter((order) => {
      return order.status === "Completed";
    });
    setFilteredFeedBack(processing);
    setSelectedTab("Completed");
  };

  const filterStatusByRejected = () => {
    const processing = feedBackData.filter((order) => {
      return order.status === "Deleted";
    });
    setFilteredFeedBack(processing);
    setSelectedTab("Deleted");
  };

  const filterStatusByNew = () => {
    const processing = feedBackData.filter((order) => {
      return order.status === undefined;
    });
    setFilteredFeedBack(processing);
    setSelectedTab("New");
  };

  const filterAllOrders = () => {
    const allOrderFilter = feedBackData.filter((order) => {
      return order.status !== "Deleted";
    });
    setFilteredFeedBack(allOrderFilter);
    setSelectedTab("All");
  };

  // Search Box filter function
  const handleSearchBox = (e) => {
    const res = feedBackData?.filter((feedback) => {
      return (
        feedback.fullName
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        feedback.request.toLowerCase().includes(e.target.value.toLowerCase()) ||
        feedback?.followUp
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        feedback?.building
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        feedback?.status
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        feedback?.anonymous
          ?.toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        feedback?.suggestion
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });
    // console.log(orderDetails);
    setFilteredFeedBack(res);
  };

  const deleteAll = async () => {
    setIsloading(true);
    const collectionRef = collection(db, "Suggestions");

    // Create a query to get documents where 'status' is 'deleted'
    const q = query(collectionRef, where("Status", "==", "Deleted"));

    try {
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (docs) => {
        const docRef = doc(db, "Suggestions", docs.id);
        try {
          await deleteDoc(docRef);
        } catch (error) {
          console.error(`Error deleting document with ID ${docs.id}:`, error);
        }
      });
      RefreshFeedback();
      setIsloading(false);
      console.log(
        "Documents with 'status' set to 'deleted' deleted successfully."
      );
    } catch (error) {
      console.error("Error deleting documents:", error);
    }
  };

  return (
    <div className="mainDivFeedBack">
      <div className="feedbackBlock">
        {/* order stats  */}
        <Grid container spacing={1}>
          <div className="cardMain">
            {feedBackStats.map((card, i) => {
              return (
                <Grid item xs={12} sm={8} md={4} lg={6} key={i}>
                  <div className="cards" key={i}>
                    <Typography variant="h3" mb={1} className="CardText">
                      {card.Name}
                    </Typography>
                    <div className="numberAndArrow">
                      <p>{countArray[i]}</p>
                    </div>
                    <h6
                      className="ViewMore"
                      onClick={() => console.log("clicked")}
                    >
                      View Details
                    </h6>
                  </div>
                </Grid>
              );
            })}
          </div>
        </Grid>
        <div>
          <p className="FeedbackTitle">Comments</p>

          <div className="searchBoxandTabsf">
            <div className="tabsMainf">
              <div>
                <p
                  className={`tabBtnf ${
                    selectedTab === "All" ? "selectedF" : "notSelectedF"
                  }`}
                  onClick={() => {
                    filterAllOrders();
                  }}
                >
                  All
                </p>
              </div>
              <div>
                <p
                  className={`tabBtnf ${
                    selectedTab === "New" ? "selectedC" : "notSelectedC"
                  }`}
                  onClick={() => filterStatusByNew()}
                >
                  New
                </p>
              </div>
              <div>
                <p
                  className={`tabBtnf ${
                    selectedTab === "Processing" ? "selectedC" : "notSelectedC"
                  }`}
                  onClick={() => filterStatusByProcessing()}
                >
                  Processing
                </p>
              </div>
              <div>
                <p
                  className={`tabBtnf ${
                    selectedTab === "Completed" ? "selectedC" : "notSelectedC"
                  }`}
                  onClick={() => filterStatusByCompleted()}
                >
                  Completed
                </p>
              </div>
              <div>
                <p
                  className={`tabBtnf ${
                    selectedTab === "Deleted" ? "selectedC" : "notSelectedC"
                  }`}
                  onClick={() => filterStatusByRejected()}
                >
                  Deleted
                </p>
              </div>
            </div>
            <div className="searchBarAndDelete">
              {(checkUser === "owner"|| checkUser==='TcHeadAdmin') && selectedTab === "Deleted" && (
                // <button className="deleteAll" onClick={() => deleteAll()}>
                //   <AiFillDelete size={18} color="white" className="deleteIconF"/> Delete All
                // </button>
                <button class="noselect" className="deleteAll"  onClick={() => deleteAll()}><span class="text">Delete all</span><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span></button>
              )}

              <div
                className="searchBox"
                style={{
                  marginBottom: "0.5rem",
                  borderRadius: "12px",
                  lineHeight: "1em",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Search Comments"
                  variant="outlined"
                  onChange={(e) => handleSearchBox(e)}
                  style={{
                    width: "19rem",
                    borderRadius: "12px",
                    lineHeight: "1em",
                  }}
                  className="feedbackSearchBox"
                />
              </div>
            </div>
          </div>

          <div className="feedbackDetails">
            {isLoading ? (
              <div className="loader">
                <RotatingLines
                  strokeColor="blue"
                  strokeWidth="2"
                  animationDuration="0.75"
                  width="50"
                  visible={true}
                />
              </div>
            ) : (
              <table id="orderTable" className="default">
                <thead>
                  <tr>
                    <th>ID </th>
                    <th>FullName</th>
                    <th>Request</th>
                    <th>FollowUp</th>
                    <th>Anonymous</th>
                    <th>Date</th>
                    <th>Building</th>
                    <th>Suggestions</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{feedBacksTableData}</tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Feedbacks;
