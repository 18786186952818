import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { FaCalendarAlt } from "react-icons/fa";
import CalendarDates from "../calendar";
import { useEffect, useState } from "react";
import Select from "react-select";
import { query, collection, onSnapshot } from "firebase/firestore";
import db from "../../Announcment/firebase";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#e3e9ee",
  boxShadow: 24,
  borderRadius: "12px",
  outline: "none",
  p: 4,
};
const headTag = {
  fontSize: "large",
  fontWeight: " 500",
  letterSpacing: "1px",
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "225px", // Set the desired width for the main container
    borderRadius: "8px",
    padding: "3px",
    marginTop: "10px",
    border: "0",
  }),
  menu: (provided) => ({
    ...provided,
    width: "225px", // Set the desired width for the menu list
  }),
  option: (provided, state) => ({
    ...provided,
    width: "225px",
  }),
};

export default function AvailableDate() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [squadron, setSquadron] = useState(null);
  const [comingSquadron, setComingSquadron] = useState([]);
  const owner = localStorage.getItem("owner");
  const sqdNo = localStorage.getItem("sqdNo");
  // fetching squadron
  const getSquadron = async () => {
    try {
      setSquadron();
      const res = query(collection(db, "squadrons"));
      onSnapshot(res, (snapshot) => {
        setComingSquadron([])
        snapshot.forEach((sqd) => {
          setComingSquadron((prev) => [...prev, { squadronNo: sqd.id }]);
        });
      });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const options = [
    { label: "All", value: "all" },
    ...comingSquadron.map((obj) => ({
      label: obj.squadronNo,
      value: obj.squadronNo,
    })),
  ];

  useEffect(() => {
    getSquadron();
  }, []);

  return (
    <div>
      <Button onClick={handleOpen}>
        <FaCalendarAlt
          size={30}
          color="#0465db"
          style={{ marginRight: "15px", cursor: "pointer" }}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {owner !== "owner" ? (
            <>
              {" "}
              <p style={headTag}>Select Available Dates {sqdNo}</p>
              
              <CalendarDates
              handleClose={handleClose}
              squadron={squadron&&squadron.value}
            />
            </>
          ) : (
            <>
              <p>Select Available Squadron First</p>
                <Select
                  value={squadron}
                options={options}
                onChange={setSquadron}
                styles={customStyles}
                isClearable={true}
              />
              </>
          )}
          {squadron && (
            <CalendarDates
              handleClose={handleClose}
              squadron={squadron&&squadron.value}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
