import React, { createContext, useContext, useEffect, useState } from "react";

const adminContext = createContext(null);

const useAdminContext = () => useContext(adminContext);

function ContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [verifyOwner, setVerifyOwner] = useState(false);
  const [userName, setUserName] = useState(' ');
  const [category, setCateogry] = useState("Recommendations");
  const [categoryId, setCategoryId] = useState("");
  const [verifyTCAdmin,setVerifyTCAdmin]=useState(false)
  const [verifyDDAdmin, setVerifyDDAdmin] = useState(false)
  const [verifyTCHeadAdmin,setVerifyTCHeadAdmin]=useState(false)
  const [loadUser, setLoadUser] = useState(false)
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const[faqId,setFaqId]=useState('')

  useEffect(() => {
    validate();
    // localStorage.removeItem('token')
  }, []);
  
  const validate = () => {
    const token = localStorage.getItem("token");
    if (token === "avX2#xpLe2@s9") {
      setIsAuthenticated(true);
    }
  };

  const signIn = (token) => {
    setIsAuthenticated(true);
    localStorage.setItem("token", token);
  };

  const signOut = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("owner");
    localStorage.removeItem("name");
    localStorage.removeItem("current");
    localStorage.removeItem("sqdNo");
    localStorage.removeItem("verifyOwner");
    localStorage.removeItem('verifytcAdmin');
    localStorage.removeItem('verifyddAdmin');
    localStorage.removeItem('verifytcheadAdmin');
    setVerifyTCHeadAdmin(false);
    setVerifyTCAdmin(false);
    setVerifyDDAdmin(false);
    setVerifyOwner(false);
    setUserName(null);
  };

  const verifyOwnerFunc = (type,name) => {
        setUserName(name)
    if (type.toLowerCase() === "owner") {
      setVerifyOwner(true);
      localStorage.setItem("verifyOwner",true)
    } else {
      setVerifyOwner(false);
      localStorage.setItem("verifyOwner",false)
    }
    if (type.toLowerCase() === "tcadmin") {
      localStorage.setItem('verifytcAdmin',true)
      setVerifyTCAdmin(true);
    } else {
      localStorage.setItem('verifytcAdmin',false)

      setVerifyTCAdmin(false);
    }
    if (type.toLowerCase() === "ddadmin") {
      localStorage.setItem('verifyddAdmin',true)
      setVerifyDDAdmin(true);
    } else {
      localStorage.setItem('verifyddAdmin',false)
      setVerifyDDAdmin(false);
    }
    if (type.toLowerCase() === "tcheadadmin") {
      localStorage.setItem('verifytcheadAdmin',true)
      setVerifyTCHeadAdmin(true);
    } else {
      localStorage.setItem('verifytcheadAdmin',false)
      setVerifyTCHeadAdmin(false);
    }


  };

  const faqData = (category, id) => {
    console.log(">>>>",id)
    setCateogry(category);
    setCategoryId(id);
  };

  const checkUserFunc = (value) => {
    setLoadUser(value)
  }

  const faqIdFun = (id) => {
    setFaqId(id)
  }
  return (
    <adminContext.Provider
      value={{
        signIn,
        signOut,
        isAuthenticated,
        verifyOwner,
        verifyOwnerFunc,
        faqData,
        category,
        categoryId,
        userName,
        verifyDDAdmin,
        verifyTCAdmin,
        verifyTCHeadAdmin,
        loadUser,
        checkUserFunc,
        setToggleSideBar,
        toggleSideBar,
        setUserName,
        faqIdFun,
        faqId
      }}
    >
      {children}
    </adminContext.Provider>
  );
}

export default ContextProvider;

export { useAdminContext };
