export const statData = [
    {
    Name: "New           Orders",
      Count: "45",
      Path: "/",
    },
    {
      Name: "Orders In Process",
      Count: "45",
      Path: "/",
    },
    {
      Name: "Completed Orders",
      Count: "45",
      Path: "/",
    },
    // {
    //   Name: "Deleted Orders",
    //   Count: "45",
    //   Path: "/",
    // },

  ];